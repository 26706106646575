:root {
  --bs-link-color: #6941c6;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Inter", sans-serif;
}

a {
  text-decoration: none;
}

.btn {
  --bs-btn-border-radius: 0.5rem;
  --bs-btn-padding-x: 0.75rem;
  --bs-btn-padding-y: 0.5625rem;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
}

.btn-primary {
  --bs-btn-bg: #b42318;
  --bs-btn-border-color: #912018;
  --bs-btn-hover-bg: #7f56d9;
  --bs-btn-hover-border-color: #7f56d9;
  --bs-btn-focus-shadow-rgb: none;
  --bs-btn-active-bg: #53389e;
  --bs-btn-active-border-color: #53389e;
  --bs-btn-disabled-bg: #891e16;
  --bs-btn-disabled-border-color: #891e16;
}

.btn-secondary {
  --bs-btn-bg: #7f56d9;
  --bs-btn-border-color: #7f56d9;
  --bs-btn-hover-bg: #891e16;
  --bs-btn-hover-border-color: #891e16;
  --bs-btn-focus-shadow-rgb: none;
  --bs-btn-active-bg: #891e16;
  --bs-btn-active-border-color: #891e16;
  --bs-btn-disabled-bg: #7f56d9;
  --bs-btn-disabled-border-color: #7f56d9;
}

.btn-outline-primary {
  --bs-btn-disabled-color: #fff;
  --bs-btn-color: #b42318;
  --bs-btn-border-color: #b42318;
  --bs-btn-hover-bg: #b42318;
  --bs-btn-hover-border-color: #b42318;
  --bs-btn-focus-shadow-rgb: none;
  --bs-btn-active-bg: #b42318;
  --bs-btn-active-border-color: #b42318;
  --bs-btn-disabled-bg: #891e16;
  --bs-btn-disabled-border-color: #891e16;
}

button.transparent-btn.btn.btn-primary {
  background: #ffffff;
  border-color: #d0d5dd;
  font-weight: 500;
  font-size: 16px;
  line-height: 1.4;
  color: #344054;

  &:hover {
    background-color: rgb(208 213 221 / 20%);
  }
}

.form-label {
  font-weight: 500;
  font-size: 14px;
  line-height: 1.4;
  color: #344054;
}

.form-control {
  padding: 0.5625rem 0.75rem;
  border-radius: 0.5rem;
  border: 1px solid #d0d5dd;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  color: #667085;

  &:focus {
    color: #212529;
    background-color: #fff;
    border-color: #7f56d9;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgb(127 86 217 / 25%);
  }
}

header {
  .navbar {
    padding-top: 15px;
    padding-bottom: 15px;
    .navbar-nav {
      .nav-link {
        color: #101828;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        padding: 0;
        margin-right: 32px;
        position: relative;
        white-space: nowrap;
        &:hover,
        &.active {
          color: #b42318;
          &:before,
          &::after {
            width: 30px;
          }
        }
        &:after {
          content: "";
          position: absolute;
          bottom: -10px;
          right: 0;
          background-color: #b42318;
          width: 0;
          height: 3px;
          transition: all 0.3s ease 0s;
        }
        &:before {
          content: "";
          position: absolute;
          top: -10px;
          left: 0;
          background-color: #b42318;
          width: 0;
          height: 3px;
          transition: all 0.3s ease 0s;
        }
        &:last-child {
          margin-right: 0;
        }
      }
    }
    .profile-section {
      position: relative;
      margin-left: 30px;
    }
    .btn-profile {
      box-shadow: none;
      border: none;
      padding: 0;
    }
    .dropdown-menu {
      padding: 10px;
      left: unset;
      right: 0;
      .dropdown-item {
        padding: 0;
        &.active,
        &:active {
          background-color: #ffffff;
        }
      }
    }
    .online-tik {
      position: absolute;
      right: 0;
      background: #7f56d9;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      border: 1.5px solid #ffffff;
      bottom: 4px;
    }
    .offcanvas-body {
      .btn-base-button {
        font-weight: 700;
        padding: 0;
        box-shadow: none;
        font-size: 16px;
        line-height: 20px;
        white-space: nowrap;
        border: none;
        .plus-icon {
          color: #b42318;
        }
      }
      .btn-login-button {
        box-shadow: none;
        padding: 0 30px 0 48px;
        color: #667085;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        border: none;
        white-space: nowrap;
      }
      .btn-primary,
      .btn-outline-secondary {
        font-weight: 500;
        padding: 10px 18px;
        font-size: 16px;
        line-height: 20px;
        white-space: nowrap;
      }
    }
  }
}

.main-content {
  -webkit-flex-grow: 1;
  flex-grow: 1;
}

footer {
  margin-top: auto;
  .footer-top {
    background-color: #101828;
    padding-top: 65px;
    padding-bottom: 45px;
    margin-top: 100px;
  }
  .footer-logo {
    margin-bottom: 32px;
  }
  .footer-discription-area {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #667085;
  }
  .footer-col {
    width: 20%;
    display: inline-block;
    padding-right: 10px;
    .footer-title {
      color: #b42318;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      text-transform: capitalize;
      margin-bottom: 16px;
    }
    ul {
      margin-bottom: 0;
      list-style: none;
      padding-left: 0;
    }
    a {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: #667085;
      margin-bottom: 10px;
      display: inline-block;
      text-transform: capitalize;
      &:hover {
        color: rgba(255, 255, 255, 0.8);
      }
    }
    label {
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      color: #fff;
      text-transform: capitalize;
      background: rgba(255, 255, 255, 0.2);
      border: 1px solid rgba(255, 255, 255, 0.3);
      border-radius: 16px;
      padding: 2px 9px;
      margin-left: 8px;
    }
    li {
      &:last-child {
        a {
          margin-bottom: 0;
        }
      }
    }
  }

  .footer-bottom {
    background: #b42318;
    color: #fff;
    padding-top: 48px;
    padding-bottom: 48px;
  }
  .copyright {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    a {
      color: #ffffff;
      &:hover {
        color: rgba(255, 255, 255, 0.8);
      }
    }
  }
  .footer-icon {
    font-size: 24px;
    color: #ffffff;
    display: inline-flex;
    margin-right: 24px;
    &:hover {
      color: rgba(255, 255, 255, 0.8);
    }
    &:last-child {
      margin-right: 0;
    }
  }
}

.column-width-20per {
  flex: 0 0 auto;
  width: 20%;
}

.width-40px {
  width: 40px;
}

.width-50px {
  width: 50px;
}

.width-75px {
  width: 75px;
}

.width-100px {
  width: 100px;
}

.width-150px {
  width: 150px;
}

.width-200px {
  width: 200px;
}

.width-250px {
  width: 250px;
}

.width-300px {
  width: 300px;
}

.width-350px {
  width: 350px;
}

.music-tab-item {
  width: 100%;
  // overflow: overlay;
  // overflow: hidden;
  // white-space: nowrap;
  position: relative;


  .list-group.list-group-horizontal{
    width: 100%;
    overflow: overlay;
    white-space: nowrap;

    &.active{
      width: calc(100% - 90px);
      margin: 0 auto;
    }
    &::-webkit-scrollbar {
      height: 5px;
      background-color: transparent;
      border-radius: 20px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #eaecf0;
      border-radius: 20px;
    }
  }

  .list-group-button {
    .slick-arrow{
        &.slick-prev{
            left: 0;
            width: 36px;
            height: 36px;
            background: #b42318;
            border: 1px solid #b42318;
            border-radius: 50%;
            transition: 0.4s all;
            &:before {
                content: "\f104";
                font-family: "Font Awesome 6 Pro";
                opacity: 1;
            }
        }
        &.slick-next{
            width: 36px;
            height: 36px;
            background: #b42318;
            border: 1px solid #b42318;
            border-radius: 50%;
            transition: 0.4s all;
            right: 0;
            &:before{
                content: "\f105";
                font-family: "Font Awesome 6 Pro";
                opacity: 1;
            }
        }
    }
}
}
.infinite-scroll-component {
  width: 100%;
  min-height: 85px;
  max-height: 85px;
  &::-webkit-scrollbar {
    width: 5px;
    background-color: transparent;
    border-radius: 20px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #eaecf0;
    border-radius: 20px;
  }
}
/*----------------*/
.account-profile-menu-top .infinite-scroll-component {
  // min-height: unset;
  overflow: scroll !important;
  // max-height: unset;
  min-height: auto;
  max-height: 285px;
  padding-right: 7px;
}

.remember-me {
  input {
    width: 16px;
    height: 16px;
    background: #ffffff;
    border: 1px solid #d0d5dd;
    border-radius: 4px;
  }
  .remember-text {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #344054;
  }
}

/*14-10-2022*/
footer {
  margin-top: 100px;
  .footer-top {
    padding-top: 30px;
    padding-bottom: 30px;
    margin-top: 0;
  }
  .footer-bottom {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}

/*27/01/2023*/
.artist-profile.new-class {
  .artist-top-col .artist-img{
    position: relative;
  }
  // .artist-top-col .artist-verifie-tick {
  //   bottom: 90px;
  // }
}
/*27/01/2023*/
.artist-profile .artist-img.artist-bottom-section.banner-artist-cu-img img{
  height: auto !important;
  aspect-ratio: auto;
}