.class-add-tab-in-for-awards {
  margin-top: 40px;
  .nav-tabs {
    border: 1px solid rgba(208, 213, 221, 0.29);
    padding: 10px;
    border-radius: 10px;
    display: inline-flex;
    .nav-link {
      border: 1px solid #d0d5dd;
      border-radius: 6px;
      margin-right: 8px;
      color: #101828;
    }
  }
}
//.nav-link.active {
// background-color: #b42318 !important;
// color: #fff !important;
//}

.nav-tabs .banner-title-content {
  font-style: normal;
  font-weight: 600;
  font-size: 48px;
  line-height: 60px;
  text-align: center;
  letter-spacing: -0.02em;
}
.created-page {
  margin-top: 50px !important;
  .title-class-add {
    font-weight: 500;
    font-size: 30px;
    line-height: 38px;
    padding-bottom: 19px;
  }
}
.Applied-page-main {
  margin-top: 50px !important;
  .title-class-add {
    font-weight: 500;
    font-size: 30px;
    line-height: 38px;
    padding-bottom: 19px;
  }
}
.Savedjob-class-main {
  margin-top: 50px !important;
  .title-class-add {
    font-weight: 500;
    font-size: 30px;
    line-height: 38px;
    padding-bottom: 19px;
  }
}
.page-top-banner {
  img {
    object-fit: cover;
  }
}
.all-dropdown-class {
  display: flex;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  justify-content: center;
  grid-gap: 8px;
  .dropdown-one {
    position: relative;
    &::before {
      content: "";
      position: absolute;
      width: 20px;
      height: 20px;
      top: 12px;
      left: 12px;
      background: url("../media/img/job-title.png") no-repeat center center /
        contain;
    }
    select {
      padding-left: 40px;
      width: 240px;
      height: 48px;
      color: #101828;
    }
  }
  .dropdown-two {
    position: relative;
    &::before {
      content: "";
      position: absolute;
      width: 20px;
      height: 20px;
      top: 12px;
      left: 12px;
      background: url("../media/img/location.png") no-repeat center center /
        contain;
    }
    select {
      padding-left: 40px;
      width: 240px;
      height: 48px;
    }
  }
  .dropdown-three {
    position: relative;
    &::before {
      content: "";
      position: absolute;
      width: 20px;
      height: 20px;
      top: 12px;
      left: 12px;
      background: url("../media/img/company.png") no-repeat center center /
        contain;
    }
    select {
      padding-left: 40px;
      width: 240px;
      height: 48px;
    }
  }
}
img.pdfpassword {
  display: block;
  margin: 0 auto;
}
.cards-class-all {
  margin-top: 90px;
  .button-class {
    button {
      width: auto;
    }
  }
  button {
    padding: 12px 15px;
    width: 50%;
    height: 48px;
    line-height: 23px;
  }
  .card {
    margin: auto auto 20px auto;
    width: 100%;
  }
  .add-all-two-title-and-cards {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #6941c6;
    border-bottom: 1px solid #eaecf0;
    padding-bottom: 10px;
  }
  .add-text-change-class {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    height: 120px;
    overflow: auto;
    line-height: 24px;
    color: #667085;
  }
  .title-class {
    height: 28px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    color: #101828;
    padding: 0 0 0 0;
    margin: 18px 0 12px 0;
    width: 100%;
  }
  .location-time-both-class {
    color: #667085;
  }
}
.Awards-page-main {
  .main-second-nominess-class {
    .card-img-top.rounded-0 {
      width: 100%;
      height: auto;
      aspect-ratio: 1/1;
      object-fit: cover;
    }
  }
}

.banner-text-add {
  color: #eaecf0;
  font-size: 20px;
  font-family: "Inter";
  max-width: 768px;
  align-items: center;
  line-height: 30px;
  width: 100%;
  margin: 0 auto 25px auto;
}

.Billing-page-main {
  margin-top: 30px;
  .second-card-add {
    border: 1px solid #eaecf0;
    border-radius: 8px;
    padding: 16px;
    .img-billing-visa {
      height: 40px;
      width: 58px;
    }
  }
  .switch-and-billing {
    .text-muted {
      color: #6941c6 !important;
      text-align: right;
      background: none;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
    }
  }
  .progress {
    height: 8px;
    .progress-bar {
      background-color: #6941c6;
    }
  }
  .plan-month {
    font-size: 36px;
    font-weight: 600;
    .gray {
      color: #101828;
      font-size: 48px;
      font-weight: 600;
    }
    .month {
      font-weight: 500;
      font-size: 16px;
      color: #667085;
    }
  }
  .title-add-flexible {
    text-align: left;
    margin: -14px 0 0 0px;
  }
  p.dayss-renewal.card-text {
    text-align: left;
    margin: 24px 0px 12px 0;
    font-weight: 500;
    font-size: 14px;
  }
  span.badge.text-dark.bg-light {
    background-color: #f9f5ff !important;
    border-radius: 16px;
    padding: 2px 10px;
    font-size: 14px;
    line-height: 30px;
    margin: 0 0 0 8px;
    color: #6941c6 !important;
  }
  .card-body {
    padding: 24px;
  }
  .card-footer {
    padding: 17px 24px 16px 24px;
    background: none;
  }
  .payment-and-change {
    text-align: left;
    padding: 0 0 12px 0;
  }
  .main-two-class {
    .text-and-content-mix {
      .visa-class {
        font-size: 14px;
        color: #344054;
        font-weight: 500;
      }
      .two-mix-class {
        font-size: 14px;
        font-weight: 400;
      }
    }
  }
  .billing-button {
    margin-top: -70px;
    float: right;
  }
  .title-class-add-new {
    .title-add {
      color: #101828;
      font-weight: 500;
      font-size: 30px;
    }
    .class-second-title {
      color: #667085;
      font-weight: 400;
      font-size: 16px;
      margin-bottom: 32px;
      border-bottom: 1px solid #eaecf0;
      padding-bottom: 10px;
    }
  }
  .title-class-add-new-two {
    margin-bottom: 24px;
    .title-add-invoice {
      font-weight: 500;
      font-size: 18px;
      color: #101828;
    }
    .class-second-title-two {
      font-weight: 400;
      font-size: 14px;
      color: #667085;
    }
  }
  .download {
    .download-all-class {
      padding: 8px 14px;
      font-size: 14px !important;
      color: #667085;
    }
  }
  .pdf-class {
    background: #f4ebff;
    border-radius: 20px;
  }
  table {
    font-size: 14px;
    .gray-color {
      color: #667085;
    }
  }
  .class-fourteen {
    margin: -17px 0 0 0;
  }
  .cards-class-all {
    margin-top: 40px !important;
  }
}

.table-add-inn {
  .table {
    tr {
      vertical-align: middle;
    }

    thead {
      tr {
        height: 44px;

        th {
          color: #667085;
          font-weight: 500;
          font-size: 14px;
          background: #f9fafb;
          border-top: 1px solid #eaecf0;
          border-bottom: 1px solid #eaecf0;

          &:first-child {
            border-left: 1px solid #eaecf0;
            border-radius: 5px;
          }

          &:last-child {
            border-right: 1px solid #eaecf0;
          }
        }
      }
    }
    tbody {
      tr {
        height: 70px;

        td {
          border-top: 1px solid #eaecf0;
          border-bottom: 1px solid #eaecf0;

          &:first-child {
            border-left: 1px solid #eaecf0;
            border-radius: 5px;
          }

          &:last-child {
            border-right: 1px solid #eaecf0;
          }
        }
      }
    }
    .light-green {
      font-weight: 500;
      font-size: 12px;
      text-align: center;
      color: #027a48;
      background: #ecfdf3;
      border-radius: 16px;
      padding: 3px 9px 3px 7px;
    }
    .light-warning {
      font-weight: 500;
      font-size: 12px;
      text-align: center;
      color: rgb(255, 193, 7);
      background: rgb(255, 243, 206);
      border-radius: 16px;
      padding: 3px 9px 3px 7px;
    }
  }
}

.purple-img {
  width: 40px;
  height: 40px;
  background: #f4ebff;
  border-radius: 20px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-right: 0.75rem;
}

@media only screen and (max-width: 1199px) {
  .cards-class-all button {
    padding: 11px 34px;
  }
  .Apply-button {
    font-size: 12px;
    line-height: 1;
  }
  .button-red-classs {
    line-height: 1;
    font-size: 12px;
  }
}

@media only screen and (max-width: 990px) {
  .cards-class-all {
    .Apply-button {
      font-size: 14px;
      line-height: 1.3;
    }
  }
  // .button-class {
  //   display: none;
  // }
  .button-red-classs {
    line-height: 1.3;
    font-size: 14px;
  }
}
@media only screen and (max-width: 800px) {
  .all-dropdown-class {
    flex-direction: column;
    .dropdown-one select {
      width: 100%;
    }
  }
  .all-dropdown-class {
    .dropdown-two select {
      width: 100%;
    }
  }
  .all-dropdown-class {
    .dropdown-three select {
      width: 100%;
    }
  }
  .page-top-banner {
    .banner-title-content {
      font-size: 38px;
      line-height: 40px;
    }
  }
}
@media (min-width: 320px) and (max-width: 768px) {
  span.badge.text-dark.bg-light {
    display: none !important;
  }
  span.class-pay-change {
    display: none;
  }
  .Billing-page-main {
    .title-add-flexible {
      margin: 0px 0 0 0px;
    }
    .class-fourteen {
      font-weight: 500;
      font-size: 14px;
      color: #6941c6;
      background: #f9f5ff;
      border-radius: 16px;
      padding: 2px 10px;
      margin: 0 0 0 10px;
      .gray {
        font-weight: 500;
        font-size: 14px;
        color: #6941c6;
      }
      .month {
        font-weight: 500;
        font-size: 14px;
        color: #6941c6;
      }
      .class-pay-change {
        display: none;
      }
    }
    .plan-month {
      justify-content: normal !important;
    }
  }
}

@media only screen and (max-width: 767px) {
  .cards-class-all {
    margin-top: 45px;
  }
  .page-top-banner {
    .banner-title-content {
      position: relative;
      line-height: 38px;
      padding-bottom: 40px;
      &::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 156px;
        background: url("../media/img/banner-title-bg.png") no-repeat center
          center;
        width: 200px;
        height: 18px;
        background-size: cover;
      }
    }
  }
}
@media only screen and (max-width: 575px) {
  .page-top-banner {
    .banner-title-content {
      font-size: 25px;
      line-height: 30px;
      &::after {
        left: 100px;
      }
    }
    .banner-text-add {
      font-size: 14px;
      line-height: 20px;
    }
  }
  .videos {
    .music-tabs a {
      font-size: 12px;
    }
  }
  .Billing-page-main {
    .title-class-add-new-two {
      flex-direction: column;
      .download {
        margin-top: 10px;
      }
    }
  }
}
@media only screen and (max-width: 480px) {
  .created-page {
    .title-class-add {
      flex-direction: column;
      grid-gap: 10px;
    }
  }
  .Applied-page-main {
    .title-class-add {
      font-size: 25px;
    }
  }
  .created-page {
    .title-class-add {
      font-size: 25px;
    }
  }
  .Savedjob-class-main {
    .title-class-add {
      font-size: 25px;
    }
  }
  .Billing-page-main {
    .billing-button {
      font-size: 10px;
      line-height: 1;
      padding: 6px 8px;
      margin: -70px -10px 0 0;
    }
  }
}
@media only screen and (max-width: 389px) {
  .page-top-banner {
    img {
      height: 450px;
    }
    .banner-title-content {
      &::after {
        left: 54px;
      }
    }
  }
  .created-page {
    button.transparent-btn.btn.btn-primary {
      padding: 8px 37px;
    }
  }
  .Billing-page-main {
    .card-footer {
      padding: 12px 17px 10px 17px;
      background: none;
    }
    .billing-button {
      font-size: 10px !important;
      line-height: 1 !important;
      padding: 6px 8px;
      margin: -60px 0px 0 0;
    }
    .img.card-img-top.rounded-0 {
      height: auto;
      width: 40px;
    }
    .main-two-class {
      .text-and-content-mix {
        .visa-class {
          font-size: 11px;
          color: #344054;
          font-weight: 500;
        }
        .two-mix-class {
          font-size: 11px;
          font-weight: 400;
        }
      }
    }
    .img-viza {
      height: auto;
      width: 40px;
    }
  }
  .button-red-classs {
    line-height: 1.3;
  }
}

.view-details-modal {
  background: rgba(52, 64, 84, 0.6);
  backdrop-filter: blur(40px);
  .modal-dialog {
    max-width: 1012px;
    width: 100%;
  }
  .modal-header {
    padding: 25px 40px 25px 40px;
    border-bottom: none;
  }
  .title {
    font-weight: 500;
    font-size: 30px;
    line-height: 35px;
    text-transform: capitalize;
    color: #000000;
  }
  .btn-close {
    margin-top: auto !important;
    margin-bottom: auto !important;
    opacity: 0.38;
    &:hover {
      opacity: 0.38;
    }
  }
  .modal-body {
    padding-top: 0;
    padding-left: 35px;
    padding-right: 35px;
    padding-bottom: 35px;
  }
  .details-col {
    padding: 15px 35px;
    border-bottom: 1px solid #eaecf0;
    &:hover {
      background-color: #f9fafb;
    }
  }
  .details-col-job-title {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #667085;
  }
  .details-col-title {
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #344054;
  }
}
.job-profile-modal {
  z-index: 1111;
  background: rgba(52, 64, 84, 0.6);
  -webkit-backdrop-filter: blur(40px);
  backdrop-filter: blur(40px);
  .modal-dialog {
    max-width: 600px;
    width: 100%;
  }
  .form-check {
    .form-check-label {
      font-weight: 400;
      color: #667085;
      text-decoration: underline;
      cursor: pointer;
    }
  }
  .form-button {
    border-top: 0;
    button {
      width: 48%;
    }
  }
  .btn-close {
    background: none;
    position: relative;
    &:before {
      content: "\e12e";
      font-family: "Font Awesome 6 Pro";
      position: absolute;
      font-size: 18px;
      top: 4px;
      right: 6px;
    }
  }
  .details-col-title {
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #344054;
    text-transform: capitalize;
  }
  .modal-content {
    padding: 22px;
  }
  .modal-header {
    padding: 0 0 10px 0;
    margin-bottom: 10px;
  }
  .modal-top-title {
    font-weight: 600;
    font-size: 18px;
    line-height: 20px;
    color: #6941c6;
    text-transform: capitalize;
  }
  .job-description-section {
    .job-description-title {
      font-weight: 500;
      font-size: 18px;
      line-height: 22px;
      color: #101828;
      text-transform: capitalize;
    }
    .job-description {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #667085;
    }
    .description-title {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: #101828;
    }
    .job-description-list {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #667085;
      position: relative;
      padding-left: 20px;
      word-break: break-all;
      &:before {
        content: "";
        display: list-item;
        position: absolute;
      }
    }
    .details-col {
      span {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #667085;
      }
    }
  }
  .modal-body {
    padding: 0;
  }
  .applications-section {
    .title {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: #101828;
    }
    .icon {
      background: #f2f4f7;
      border: 6px solid #f9fafb;
      border-radius: 28px;
      width: 40px;
      height: 40px;
      display: inline-block;
      text-align: center;
    }
    .view-resume {
      font-weight: 500;
      font-size: 12px;
      line-height: 20px;
      text-transform: capitalize;
      color: #6941c6;
    }
    .view-resume-col {
      border-bottom: 1px solid #eaecf0;
      cursor: pointer;
      border-top: 1px solid #eaecf0;
    }
    .right-col {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #344054;
    }
    .left-col {
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: #344054;
    }
  }
  .termsconditions {
    .form-check-input {
      width: 18px;
      height: 18px;
      margin-top: 0.3em;
      &:focus {
        box-shadow: 0 0 0 0.25rem rgb(127, 86, 217, 0.5);
        border-color: #7f56d9;
      }
      &:checked {
        background-color: #f9f5ff;
        border-color: #7f56d9;
        &[type="checkbox"] {
          background-image: url("../../assets/media/icons/checked.svg");
          display: inline-block;
          background-size: 10px;
          background-position: center center;
        }
      }
    }
  }
}

//13-10-2022
.youvideo-col{ 
  position: relative;
  .icons, 
  .icons-right{
      right: 10px;
      left: unset !important;
      bottom: 52px;
      top: unset;
    }
}

.artist-award-container{
  .artist-award-image {
    .card-img-top{
      border-radius: 50% !important;
      aspect-ratio: 1/1;
      height: unset;
    }
  }  
}

/*14/02/23*/
.page-top-banner {
  .banner-title-content {
    position: relative;
    line-height: 55px;
    padding-bottom: 40px;
    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      right: 0;
      margin: 0 auto;
      left: 0;
      background: url("../media/img/banner-title-bg.png") no-repeat center
        center;
      width: 200px;
      height: 18px;
      background-size: cover;
    }
  }
}