@import url("./assets/lib/fontawesome/css/all.min.css");

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.cursor-pointer{
  cursor: pointer;
}
.obj-fit-cover{
  object-fit: cover;
}
.obj-fit-none{
  object-fit: none;
}
.obj-fit-sc-down{
  object-fit: scale-down;
}
/* Braintree Hosted Fields styling classes*/
.braintree-hosted-fields-focused {
  color: #212529;
  background-color: #fff;
  border-color: #7f56d9;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgb(127 86 217 / 25%);
}
.braintree-hosted-fields-focused.is-invalid {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}
.border-common{
  border: 1px solid #ebebeb;
}
.d-none{
  display: none;
}
.add-text-change-class::-webkit-scrollbar-thumb {
  background-color: rgb(193 193 193);
  border-radius: 20px;
}
.add-text-change-class::-webkit-scrollbar-thumb:hover {
  background-color: rgb(177 177 177);
}
.add-text-change-class::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
.border-radius-47 {
  border-radius: 50% ;
}
