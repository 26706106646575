.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-top: 1rem;
}

.wrapper h2 {
  font-size: 1.1rem;
  margin-bottom: 1.2rem;
}

input {
  border: 1px solid hsla(218, 100%, 42%, 1);
  outline: none;
  padding: 0 0.5rem;
  width: 300px;
  height: 40px;
  border-radius: 5px;
}

.no-suggestions {
  padding: 0.4rem;
  position: absolute;
  background: #ffffff;
  color: #000000;
  width: 350px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  padding: 0.5rem;
  font-size: 0.9rem;
  line-height: 1.33;
  font-weight: 500;
  height: 38px;
}

.suggestions {
  background: #ffffff;
  list-style: none;
  margin-top: 0;
  max-height: 190px;
  overflow-y: auto;
  padding-left: 0;
  width: 350px;
  position: absolute;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.suggestions::-webkit-scrollbar {
  width: 0.2rem;
  height: 0rem;
  border-radius: 20px;
}

.suggestions::-webkit-scrollbar-thumb {
  background-color: #888888;
}

.suggestions li {
  padding: 0.5rem;
  font-size: 0.9rem;
  font-weight: 500;
  height: 38px;
}

.suggestion-active,
.suggestions li:hover {
  cursor: pointer;
  color: #000000;
}

.suggestions li:not(:last-of-type) {
  border: 1px solid #e2e2e2;
}

@media only screen and (max-width: 992px) {
  .mobile-search .suggestions {
    border: 1px solid #eaecf0;
    border-radius: 5px;
    box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08),
      0px 4px 6px -2px rgba(16, 24, 40, 0.03);
    max-height: 400px;
    min-height: 400px;
    padding: 15px 8px 15px 15px;
    width: calc(100% - 25px);
    overflow-y: hidden;
    margin-top: 12px;
    z-index: 5;
  }
  .mobile-search .suggestions li {
    min-height: 58px;
    margin-bottom: 15px;
    height: 100%;
  }
  .mobile-search .suggestions li:not(:last-of-type) {
    border: none;
    border-bottom: 1px solid #eaecf0;
  }
  .mobile-search .suggestions .suggestions-name {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
  }
  .mobile-search .suggestions .suggestions-director {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #667085;
  }
  .no-suggestions {
    border: 1px solid #eaecf0;
    border-radius: 5px;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    padding: 15px;
    width: calc(100% - 26px);
    margin-top: 12px;
    z-index: 5;
    text-align: center;
    height: 55px;
  }
  .mobile-search .infinite-scroll-component {
    /* min-height: unset; */
    overflow: scroll !important;
    /* max-height: unset; */

    min-height: auto;
    max-height: 383px;
    padding-right: 8px;
  }
  .mobile-search .suggestions .suggestions-img img {
    width: 40px;
    height: 40px;
  }
}
