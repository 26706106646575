*:foucs {
  outline: none;
}
*:focus-visible {
  outline: none;
}
img.auth-logo {
  position: absolute;
  top: 35px;
  left: 35px;
}

.layout {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  min-height: 100vh;
}

.authentication-page {
  .signup-box {
    max-width: 400px;
    width: 100%;

    h1 {
      font-weight: 600;
      font-size: 30px;
      line-height: 1.4;
    }

    p {
      font-weight: 400;
      font-size: 16px;
      line-height: 1.4;
      color: #667085;
    }

    .already-login-line {
      font-size: 14px;
      line-height: 1.4;
      color: #667085;
      text-align: center;
      margin-top: 2rem;

      a {
        font-size: 14px;
        line-height: 20px;
      }
    }
  }

  .auth-slider {
    position: relative;

    .slider-logo {
      position: absolute;
      z-index: 1;
      left: 50%;
      transform: translateX(-50%);
      top: 60px;

      img {
        max-width: 480px;
        width: 100%;
      }

      &::after {
        content: "";
        position: absolute;
        background: url("../media/logos/arrow-img-slider.svg") no-repeat center
          center/contain;
        width: 248px;
        height: 105px;
        top: 85px;
        right: -120px;
      }
    }
    .slick-slide {
      height: 100vh;

      > div > div {
        position: relative;

        &::before {
          content: "";
          position: absolute;
          height: 100%;
          width: 100%;
          background: linear-gradient(0deg, #b32318, #b32318);
          mix-blend-mode: multiply;
        }
      }
    }

    button.slick-arrow.slick-prev {
      left: unset;
      z-index: 1;
      bottom: 155px;
      top: unset;
      right: 250px;

      &::before {
        content: "";
        position: absolute;
        height: 50px;
        width: 50px;
        background: url("../media/icons/left-arrow-white.svg") no-repeat center
          center/1rem;
        border-radius: 100px;
        border: 1px solid rgba(255, 255, 255, 0.5);
      }
    }

    button.slick-arrow.slick-next {
      right: 185px;
      z-index: 1;
      bottom: 155px;
      top: unset;

      &::before {
        content: "";
        position: absolute;
        height: 50px;
        width: 50px;
        background: url("../media/icons/right-arrow-white.svg") no-repeat center
          center/1rem;
        border-radius: 100px;
        border: 1px solid rgba(255, 255, 255, 0.5);
      }
    }

    .slider-img {
      display: block;
      width: 100%;
      height: 100vh;
      object-fit: cover;
      object-position: center;
    }

    .bottom-card-area {
      position: absolute;
      max-width: 737px;
      width: 100%;
      bottom: 100px;
      left: 50%;
      transform: translateX(-50%);
      background: rgba(0, 0, 0, 0.3);
      border: 1px solid rgba(0, 0, 0, 0.5);
      backdrop-filter: blur(24px);
      color: white;
      padding: 24px 32px;

      .discription-area {
        font-weight: 600;
        font-size: 30px;
        line-height: 1.4;
        margin-bottom: 2rem;
      }

      .artist-name {
        font-weight: 600;
        font-size: 36px;
        line-height: 1;
        letter-spacing: -0.02em;
        margin-bottom: 1rem;
      }

      .details-area {
        .artist-type {
          font-weight: 600;
          font-size: 18px;
          line-height: 28px;

          .artist-location {
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
          }
        }
      }
    }
  }
}

header {
  position: relative;
  z-index: 999;
}

.top-banner {
  position: relative;
  .slider-img {
    height: 510px;
  }
  .no-suggestions {
    width: 100%;
    max-width: 495px;
    margin-top: 8px;
    border: 1px solid #eaecf0;
    box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08),
      0px 4px 6px -2px rgba(16, 24, 40, 0.03);
    border-radius: 12px;
    padding: 15px;
    height: 51px;
    text-align: center;
  }
  .suggestions {
    right: 50%;
    transform: translate(-50%);
    left: 50%;
    z-index: 99;
    max-height: 400px;
    width: 100%;
    max-width: 495px;
    margin-top: 8px;
    border: 1px solid #eaecf0;
    box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08),
      0px 4px 6px -2px rgba(16, 24, 40, 0.03);
    border-radius: 12px;
    padding: 15px 8px 15px 15px;
    // min-height: 50px;
    overflow-y: hidden;
  }
  .infinite-scroll-component {
    min-height: auto;
    max-height: 368px;
    padding-right: 8px;
  }
  .suggestions {
    li {
      color: #101828;
      padding: 12px 0;
      text-align: left;
      height: auto;
      &:not(:last-of-type) {
        border: none;
        border-bottom: 1px solid #eaecf0;
      }
    }
    .suggestions-img {
      img {
        width: 40px;
        height: 40px;
      }
    }
    .suggestions-director {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #667085;
    }
    .suggestions-name {
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
    }
  }
  .slick-list {
    overflow: hidden;
    height: 100%;
    z-index: -1;
    width: 100%;
  }
  .slick-track {
    top: 35%;
    // transform: translateY(-50%) !important;
  }
  .award-slider .slick-track {
    display: flex !important;
    align-items: center !important;
  }
  .banner-search {
    position: relative;
    width: 100%;
    max-width: 495px;
    margin: 0 auto;
    input {
      width: 100%;
      max-width: 495px;
      height: 48px;
      background: #ffffff;
      border: 1px solid #d0d5dd;
      box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
      border-radius: 8px;
      padding-left: 50px;
      padding-top: 7px;
      padding-bottom: 7px;
      padding-right: 15px;
      color: #667085;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      caret-color: #912018;
      &::-webkit-input-placeholder {
        color: #667085;
      }
      &:-ms-input-placeholder {
        color: #667085;
      }
      &::placeholder {
        color: #667085;
      }
    }
    .search-icon {
      position: absolute;
      left: 15px;
      color: #667085;
      top: 12px;
      font-size: 19px;
    }
  }
  .slick-slider {
    position: absolute;
    top: 50%;
    margin: 0 auto;
    left: 0;
    right: 0;
    transform: translateY(-50%);
    color: #ffffff;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 1320px;
    padding-left: 10px;
    padding-right: 10px;
    width: 100%;
  }
  .banner-title {
    font-weight: 600;
    font-size: 48px;
    line-height: 55px;
    letter-spacing: -0.02em;
    display: inline-block;
    margin: 0 auto 25px;
    position: relative;
    &::after {
      content: "";
      background: url("../media/img/banner-title-bg.png") no-repeat;
      position: absolute;
      width: 204px;
      height: 18px;
      display: inline-block;
      right: -8px;
      bottom: -15px;
    }
  }
  .banner-discription-area {
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    color: #667085;
    width: 90%;
    margin: 0 auto 25px auto;
  }
  .slick-dots {
    background: #6941c6;
    bottom: 40px;
    width: auto;
    margin: 0 auto;
    border-radius: 12px;
    transform: translateX(-50%);
    left: 50%;
    height: 27px;
    padding: 0 5px;
    z-index: 9;
    li {
      button {
        padding: 0;
      }
      &.slick-active {
        button:before {
          opacity: 1;
          color: #ffffff;
        }
      }
      button:before {
        opacity: 1;
        color: #9e77ed;
        font-size: 10px;
        top: -4px;
      }
    }
  }
  .slick-next {
    right: 10px;
    &::before {
      content: "\f105";
      font-family: "Font Awesome 6 Pro";
    }
  }
  .slick-prev {
    left: 10px;
    &::before {
      content: "\f104";
      font-family: "Font Awesome 6 Pro";
    }
  }
  .slick-prev,
  .slick-next {
    z-index: 1;
    width: 36px;
    height: 36px;
    background: rgba(255, 255, 255, 0.3);
    border: 1px solid rgba(255, 255, 255, 0.5);
    border-radius: 50%;
    transition: 0.4s all;
    &::before {
      opacity: 1;
    }
    &:hover,
    &:focus {
      background: rgba(255, 255, 255, 0.3);
    }
  }
}

.videos {
  .music-tabs {
    background: #fcfcfd;
    border-bottom: 1px solid #eaecf0;
    a {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: #667085;
      padding: 10px 8px;
      margin-right: 25px;
      border-bottom: 2px solid transparent;
      margin-bottom: 15px;
      margin-top: 15px;
      &.active,
      &:hover {
        color: #b42318;
        border-color: #b42318;
      }
    }
  }
  .title {
    font-weight: 600;
    font-size: 36px;
    line-height: 40px;
    letter-spacing: -0.02em;
    color: #101828;
    margin-bottom: 20px;
    margin-top: 40px;
  }
  .directed-dy {
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: #b32318;
    margin-bottom: 12px;
  }
  .music-video-title {
    font-weight: 600;
    font-size: 24px;
    line-height: 28px;
    color: #101828;
    margin-bottom: 12px;
  }
  .artist-name {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #667085;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .icons,
  .icons-right {
    position: absolute;
    top: 10px;
    display: flex;
    flex-direction: column;
    color: #ffffff;
  }
  .icons-right {
    right: 10px;
  }
  .artist-award-container {
    .icons-right {
      background: #a90e12;
      width: 34px;
      height: 34px;
      justify-content: center;
      border-radius: 50%;
    }
  }
  .icons {
    left: 10px;
  }
  a {
    .artist-img {
      img {
        box-shadow: 0px 24px 48px -12px rgba(16, 24, 40, 0.18);
      }
    }
    &:hover {
      .music-video-title {
        color: #b42318;
      }
    }
  }
}

.artist-community {
  margin-top: 100px;
  .title-artist-community {
    font-weight: 600;
    font-size: 48px;
    line-height: 55px;
    letter-spacing: -0.02em;
    color: #101828;
    margin-bottom: 24px;
  }
  .artist-discription-area {
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color: #667085;
    margin-bottom: 40px;
  }
  .artist-community-banner {
    position: relative;
    height: 100%;
    min-height: 592px;

    .bottom-card-area {
      position: absolute;
      width: 100%;
      bottom: 0;
      background: rgba(0, 0, 0, 0.3);
      border-top: 1px solid rgba(0, 0, 0, 0.5);
      -webkit-backdrop-filter: blur(24px);
      backdrop-filter: blur(24px);
      color: white;
      padding: 24px;
      z-index: 2;
    }
    .discription-area {
      font-weight: 600;
      font-size: 29px;
      line-height: 38px;
    }
    .artist-name {
      font-weight: 600;
      font-size: 36px;
      line-height: 44px;
      letter-spacing: -0.02em;
    }
    .artist-type {
      font-weight: 600;
      font-size: 18px;
      line-height: 28px;
    }
    .artist-location {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
    }
  }
  .slick-slide {
    img {
      display: inline-block;
    }
    > div > div {
      position: relative;
    }
    &::before {
      content: "";
      position: absolute;
      height: 100%;
      width: 100%;
      background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.4) 100%
      );
      mix-blend-mode: multiply;
      z-index: 1;
    }
  }
  button.slick-arrow.slick-prev {
    left: unset;
    z-index: 1;
    bottom: 60px;
    top: unset;
    right: 140px;

    &::before {
      content: "";
      position: absolute;
      height: 50px;
      width: 50px;
      background: url("../media/icons/left-arrow-white.svg") no-repeat center
        center/1rem;
      border-radius: 100px;
      border: 1px solid rgba(255, 255, 255, 0.5);
    }
  }
  button.slick-arrow.slick-next {
    right: 65px;
    z-index: 1;
    bottom: 60px;
    top: unset;

    &::before {
      content: "";
      position: absolute;
      height: 50px;
      width: 50px;
      background: url("../media/icons/right-arrow-white.svg") no-repeat center
        center/1rem;
      border-radius: 100px;
      border: 1px solid rgba(255, 255, 255, 0.5);
    }
  }
}

/*Artist page*/
.artist-profile {
  .artist-top-col {
    position: relative;

    // &::before {
    //   content: "";
    //   position: absolute;
    //   width: 100%;
    //   height: 80px;
    //   background-color: rgba(0, 0, 0, 0.4);
    //   bottom: 0;
    //   left: 0;
    // }
    .artist-verifie-tick {
      position: absolute;
      right: 16px;
      top: 16px;
    }
    .artist-name {
      font-weight: 600;
      font-size: 36px;
      line-height: 40px;
      letter-spacing: -0.02em;
      color: #ffffff;
      position: absolute;
      bottom: 0;
      right: 0;
      left: 0;
      margin: 0 auto;
      text-align: center;
      text-transform: capitalize;
      background-color: rgba(0, 0, 0, 0.4);
      padding: 10px;
      height: 80px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  &.new-class {
    .job-title {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: #b42318;
      margin-bottom: 12px;
      display: block;
    }
    .artist-name {
      font-weight: 600;
      font-size: 36px;
      line-height: 44px;
      letter-spacing: -0.02em;
      color: #101828;
    }
    .artist-top-col {
      .artist-verifie-tick {
        position: absolute;
        bottom: 20px;
        left: unset;
        margin: 0 auto;
        text-align: center;
        top: unset;
        right: 80px;
      }
    }
    .artist-social-media {
      padding: 0;
      border: none;
      background: transparent;
      margin-top: 15px;
    }
    .artist-about-button {
      button {
        margin-top: 10px;
      }
    }
  }
  .artist-bottom-col {
    .artist-img {
      height: 355px;
      iframe {
        width: 100%;
        height: 360px;
      }
    }
  }
  .artist-social-media {
    padding: 24px;
    display: block;
    background: linear-gradient(0deg, #fcfcfd, #fcfcfd), rgba(0, 0, 0, 0.3);
    border: 1px solid #eaecf0;
    backdrop-filter: blur(24px);
    a {
      margin-right: 24px;
      display: inline-block;
      width: 40px;
      text-align: center;
      &:last-child {
        margin-right: 0;
      }
    }
  }

  &.video-profile {
    .artist-social-media {
      &.video-social-media-profile {
        padding: 0;
        border: 0;
        background: transparent;
        img {
          width: 40px;
          height: 40px;
        }
      }
    }
  }

  .artist-job-col {
    background: #fcfcfd;
    border: 1px solid #eaecf0;
    border-radius: 8px;
    padding: 32px;
    height: 100%;
    .job-title {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: #b42318;
    }
    .job-bottom-title {
      font-weight: 600;
      font-size: 36px;
      line-height: 44px;
      letter-spacing: -0.02em;
      color: #101828;
      // margin-top: 12px;
    }
  }
  .job-description {
    border-top: 1px solid #eaecf0;
    margin-top: 30px;
    padding-top: 30px;
    height: calc(100% - 138px);
  }
  .job-list-left-col {
    font-weight: 600;
    font-size: 18px;
    line-height: 32px;
    color: #667085;
    position: relative;
    padding-right: 140px;
    &:before {
      content: "";
      border-bottom: 2px dotted #d0d5dd;
      display: block;
      position: absolute;
      width: 100%;
      bottom: 10px;
    }
    label {
      background-color: #ffffff;
      position: relative;
      z-index: 1;
    }
  }
  .video-title,
  .video-title-link {
    font-weight: 500;
    font-size: 18px;
    line-height: 20px;
    color: #b42318;
    // overflow: hidden;
    // white-space: nowrap;
    // text-overflow: ellipsis;
    span {
      border-bottom: 1.5px solid;
    }
    .association-artist-text {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
  .artist-left-title {
    font-weight: 600;
    font-size: 30px;
    line-height: 34px;
    color: #101828;
    margin-bottom: 16px;
  }
  .artist-left-discription {
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    color: #667085;
    max-height: 300px;
    overflow: overlay;
    &::-webkit-scrollbar {
      width: 6px;
      background-color: transparent;
      border-radius: 20px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #eaecf0;
      border-radius: 20px;
    }
  }
  button {
    width: 133px;
    height: 48px;
    white-space: pre;
  }
  .remove-watch-later {
    width: 210px;
    height: 48px;
  }
  .star-sharp {
    width: 49px;
    i {
      font-size: 25px;
      color: #b42318;
    }
  }
  .star-sharp {
    width: 49px;
    .start-un-favour {
      font-size: 25px;
      color: #344054;
    }
  }
  .tracker {
    span {
      font-weight: 600;
      font-size: 16px;
      line-height: 18px;
      color: #667085;
    }
  }
}
.awards-section {
  .card-title {
    font-weight: 600;
    font-size: 30px;
    line-height: 33px;
    color: #101828;
    margin-bottom: 16px;
  }
  .card-text {
    font-weight: 400;
    font-size: 18px;
    line-height: 23px;
    color: #667085;
  }
  .card {
    padding: 32px;
    border-color: #eaecf0;
    background: #fcfcfd;
  }
  .award-icon {
    background: #b42318;
    border-radius: 28px;
    width: 100%;
    height: 100%;
    max-width: 48px;
    min-height: 48px;
    margin-right: 20px;
  }
  .award-discription-area {
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    color: #667085;
  }
  .award-items {
    max-height: 310px;
    overflow: overlay;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
    grid-column-gap: 10px;
    grid-row-gap: 0;
    &::-webkit-scrollbar {
      height: 5px;
      width: 5px;
      background-color: transparent;
      border-radius: 20px;
    }
    &::-webkit-scrollbar-thumb {
      height: 5px;
      background-color: #eaecf0;
      border-radius: 20px;
      width: 5px;
    }
  }
}
/*Artist page*/

/*Trending Artists*/
.page-top-banner {
  position: relative;
  & > img {
    height: 510px;
  }
  .top-banner-content {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    right: 0;
    margin: 0 auto;
    text-align: center;
  }
  .trending-banner-content {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #eaecf0;
    margin-bottom: 12px;
  }
  .banner-title-content {
    font-weight: 600;
    font-size: 48px;
    line-height: 60px;
    letter-spacing: -0.02em;
    color: #ffffff;
    margin-bottom: 24px;
    &.membership {
      font-weight: 900;
      span {
        color: #b42318;
      }
    }
  }
  .award-slider .banner-title-content {
    margin-bottom: 0px !important;
  }
  .discription-content-area {
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    text-align: center;
    color: #eaecf0;
    max-width: 768px;
    width: 100%;
    margin: 0 auto;
  }
}
/*Trending Artists*/

/*Video Page*/
.artist-job-col {
  .red-rider {
    .job-list-left-col:before {
      content: "";
      border-bottom: 2px dotted #b42318;
      display: none;
    }
  }
  .dragonl-purple {
    .job-list-left-col:before {
      content: "";
      border-bottom: 2px dotted #6941c6;
    }
    .video-title,
    .video-title-link {
      color: #6941c6;
    }
  }
  .edit-icon {
    cursor: pointer;
  }
}
.artist-job-col {
  .edit-icon {
    color: #b42318;
  }
}

.suggestions {
  &.modal {
    background: rgba(52, 64, 84, 0.6);
    backdrop-filter: blur(40px);
  }
  .modal-dialog {
    width: 100%;
    max-width: 580px;
  }
  .suggestions-form {
    padding-top: 40px;
    padding-left: 45px;
    padding-right: 45px;
    .form-label {
      font-weight: 500;
      font-size: 18px;
      line-height: 20px;
      text-transform: capitalize;
      color: #344054;
      margin-bottom: 14px;
    }
  }
  .modal-footer {
    border-top: none;
    padding: 20px;
    button {
      width: 49%;
      margin-top: 0;
      margin-left: 0;
      margin-bottom: 0;
      margin-right: 0;
      height: 48px;
    }
  }
}
/*Video Page*/
.subscription {
  .footer-top {
    padding-top: 220px;
    margin-top: 0;
  }
  .btn-base-button {
    color: #b42318;
    position: relative;
    &::after {
      content: "";
      border-bottom: 3px solid #b42318;
      display: block;
      position: absolute;
      bottom: -20px;
      width: 70px;
    }
  }
}
.plan-price {
  margin-bottom: 100px;
  margin-top: -96px;
  position: relative;
  .plan-card {
    background: #ffffff;
    border: 1px solid #eaecf0;
    box-shadow: 0px 12px 16px -4px rgb(16 24 40 / 8%),
      0px 4px 6px -2px rgb(16 24 40 / 3%);
    border-radius: 16px;
    padding: 32px;
    position: relative;
  }
  .access-list {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    padding-bottom: 30px;
    border-bottom: 1px solid #eaecf0;
    margin-bottom: 30px;
    padding-top: 30px;
    border-top: 1px solid #eaecf0;
    margin-top: 30px;
    min-height: 250px;
    height: 250px;
    padding-right: 10px;
    overflow: overlay;
    &::-webkit-scrollbar {
      width: 6px;
      background-color: transparent;
      border-radius: 20px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #eaecf0;
      border-radius: 20px;
    }
    .fa-circle-check {
      font-size: 20px;
      margin-right: 10px;
    }
  }
  .enable {
    color: #667085;
    .fa-circle-check {
      color: #b42318;
    }
  }
  .disable {
    text-decoration: line-through;
    color: #d0d5dd;
    .fa-circle-check {
      color: #d0d5dd;
    }
  }
  .popular-lebal {
    position: absolute;
    top: -30px;
    right: -135px;
    left: 0;
    text-align: center;
  }
  .plan-label {
    span {
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: #6941c6;
      background: #f9f5ff;
      mix-blend-mode: multiply;
      border-radius: 16px;
      padding: 4px 15px;
      display: inline-block;
      text-transform: uppercase;
    }
  }
  .monthly-price {
    font-weight: 600;
    font-size: 48px;
    line-height: 52px;
    letter-spacing: -0.02em;
    color: #101828;
    margin-bottom: 8px;
    margin-top: 28px;
  }
  .billing {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #667085;
  }
  button {
    height: 48px;
  }
}

.support-section {
  background: #f9fafb;
  padding: 96px 0;
  .support-icon {
    width: 48px;
    height: 48px;
    background: #b42318;
    border-radius: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    color: #ffffff;
  }
  .support-title {
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: #101828;
  }
  .support-discription-area {
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
    color: #667085;
    max-width: 355px;
    margin: 0 auto;
    width: 100%;
    span {
      color: #667085;
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.faqs-section {
  padding-top: 100px;
  padding-bottom: 100px;
  .support-title {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #b42318;
  }
  .faq-title {
    font-weight: 600;
    font-size: 36px;
    line-height: 40px;
    letter-spacing: -0.02em;
    color: #101828;
    margin-bottom: 15px;
    margin-top: 12px;
  }
  .faq-discription-area {
    font-weight: 400;
    font-size: 18px;
    line-height: 25px;
    color: #667085;
  }
  .faq-accordion {
    .accordion-item {
      border: none;
      margin-bottom: 40px;
    }
    .accordion-button {
      font-weight: 500;
      font-size: 18px;
      line-height: 28px;
      color: #101828;
      padding-left: 32px;
      padding-top: 32px;
      padding-right: 32px;
      padding-bottom: 8px;
      height: auto;
      &::after {
        content: "\f055";
        font-family: "Font Awesome 6 Pro";
        background-image: none;
        color: #98a2b3;
        width: auto;
        height: auto;
        font-size: 20px;
      }
      &:not(.collapsed)::after {
        content: "\f056";
        font-family: "Font Awesome 6 Pro";
        transform: unset;
      }
      &:focus {
        box-shadow: none;
      }
    }
    .accordion-body {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #667085;
      padding-top: 8px;
      padding-right: 80px;
      padding-left: 32px;
      padding-bottom: 32px;
    }
    .accordion-button:not(.collapsed) {
      background: #f9fafb;
      box-shadow: none;
      border-top-right-radius: 16px;
      border-top-left-radius: 16px;
      // padding-bottom: 0;
    }
    .accordion-collapse {
      background: #f9fafb;
      border-bottom-right-radius: 16px;
      border-bottom-left-radius: 16px;
    }
  }
}

.today-free-section {
  margin-bottom: -100px;
  position: relative;
  .today-free-col {
    background: #f9fafb;
    box-shadow: 0px 20px 24px -4px rgb(16 24 40 / 8%),
      0px 8px 8px -4px rgb(16 24 40 / 3%);
    border-radius: 16px;
    padding: 65px;
    .free-trial-title {
      font-weight: 600;
      font-size: 30px;
      line-height: 38px;
      color: #101828;
    }
    .free-trial-discription-area {
      font-weight: 400;
      font-size: 20px;
      line-height: 30px;
      color: #667085;
      margin-top: 8px;
    }
    button {
      height: 48px;
    }
  }
}

.account-profile-menu {
  background: #101828;
  color: #fff;
  padding-top: 12px;
  padding-bottom: 12px;
  .profile-menu-left {
    border-right: 1px solid #b42318;
  }
  .messages-col {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #f2f4f7;
    padding: 8px 12px;
  }
  .messages-number {
    padding: 2px 8px;
    background: #f9f5ff;
    border-radius: 16px;
    color: #6941c6;
  }
  .notification-tab {
    padding: 8px 12px;
  }
  .active {
    border-radius: 6px;
    background: #344054;
  }
  .nav-link {
    color: #ffffff;
  }
  .dropdown-menu {
    background: #101828;
    width: 202px;
    padding: 5px;
    transform: translate(0px, 52px) !important;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  .dropdown-item {
    color: #fff;
    border-bottom: 1px solid #475467;
    padding-top: 10px;
    padding-bottom: 10px;
    &:last-child {
      border-bottom: none;
    }
    &.active,
    &:hover,
    &:focus,
    &:active {
      background-color: #475467;
      border-radius: 5px;
    }
  }
  .dropdown-toggle {
    &::after {
      display: inline-block;
      margin-left: 8px;
      vertical-align: unset;
      content: "\f107";
      border-top: none;
      border-right: none;
      border-bottom: 0;
      border-left: none;
      font-family: "Font Awesome 6 Pro";
      color: #667085;
    }
  }
  .nav-item {
    &.show {
      .dropdown-toggle {
        &::after {
          content: "\f106";
        }
      }
    }
  }
  .search-col {
    position: relative;
    .form-control {
      background: #344054;
      border: 1px solid #344054;
      color: #ffffff;
      padding-left: 40px;
      width: 320px;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      &::placeholder {
        color: #ffffff;
      }
      &:-ms-input-placeholder {
        color: #ffffff;
      }
      &::-ms-input-placeholder {
        color: #ffffff;
      }
      &:focus {
        box-shadow: 0 0 0 0.25rem rgb(52 64 84 / 25%);
      }
    }
    .btn-search-icon {
      position: absolute;
      font-size: 14px;
      color: #fff;
    }
  }
}
.account-profile {
  .account-title {
    font-weight: 500;
    font-size: 30px;
    line-height: 38px;
    color: #101828;
  }
  .notification-lists {
    background: #ffffff;
    border: 1px solid #eaecf0;
    box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1),
      0px 1px 2px rgba(16, 24, 40, 0.06);
    border-radius: 8px;
  }
  .notification-list {
    padding: 16px 24px;
    border-bottom: 1px solid #eaecf0;
    &:hover {
      background: #f9fafb;
    }
    &:last-child {
      border-bottom: none;
    }
    .message-title {
      font-weight: 600;
      font-size: 14px;
      line-height: 18px;
      color: #101828;
    }
    .message-discription-area {
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      color: #667085;
      &.dateformat {
        min-width: 95px;
      }
    }
  }
}

.account-profile-col {
  max-width: 560px;
  margin: 0 auto;
  .profile-title {
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: #101828;
  }
  .profile-discription {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #667085;
    margin-top: 4px;
  }
  .form-password {
    border-top: 1px solid #eaecf0;
    .form-control {
      padding-right: 12px;
      background-color: #ffffff;
      font-size: 15px;
      &.invalid {
        border-color: #dc3545;
        &:focus {
          box-shadow: 0 0 0 0.25rem rgb(220 53 69 / 25%);
        }
      }
      &.valid {
        border-color: #198754;
        &:focus {
          box-shadow: 0 0 0 0.25rem rgb(25 135 84 / 25%);
        }
      }
    }
    .valid ~ .valid-message,
    .invalid ~ .invalid-message {
      font-weight: 500;
      font-size: 14px;
      line-height: 1.4;
      display: block;
    }
    .invalid ~ .invalid-message {
      color: #dc3545;
    }
    .valid ~ .valid-message {
      color: #198754;
    }
    .invalid-message,
    .valid-message {
      display: none;
    }
    .form-label {
      &.tagline {
        color: #667085;
        margin-bottom: 0;
        font-weight: 400;
      }
    }
    .form-button {
      border-top: 1px solid #eaecf0;
      margin-top: 20px;
      padding-top: 20px;
      button {
        height: 40px;
        line-height: 18px;
      }
    }
  }
}

.upgrade-plan-section {
  .upgradee-discription {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #667085;
  }
  .upgrade-title {
    font-weight: 500;
    font-size: 30px;
    line-height: 38px;
    color: #101828;
  }
}
.btn-disabled-primary {
  background: #d0d5dd;
  border: 1px solid #d0d5dd;
  box-shadow: 0px 1px 2px rgb(16 24 40 / 5%);
  border-radius: 8px;
  color: #ffffff;
  pointer-events: none;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}
.account-profile-col {
  .form-profile {
    background: #ffffff;
    border: 1px solid #eaecf0;
    box-shadow: 0px 1px 3px rgb(16 24 40 / 10%), 0px 1px 2px rgb(16 24 40 / 6%);
    border-radius: 8px;
  }
  .payment-amount {
    position: relative;
    .form-select {
      height: 40px;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #101828;
      padding-left: 40px;
      &:focus {
        border-color: #d0d5dd;
        box-shadow: none;
      }
    }
    &::before {
      content: "";
      background: url("../media/icons/currency-dollar.svg") no-repeat;
      width: 20px;
      height: 20px;
      display: block;
      position: absolute;
      bottom: 10px;
      left: 11px;
    }
  }
  .email-address {
    position: relative;
    .form-control {
      padding-left: 40px;
    }
    .gmail-icon {
      position: absolute;
      top: 39px;
      left: 13px;
      color: #667085;
      font-size: 18px;
    }
  }
  .form-button {
    border-top: 1px solid #eaecf0;
  }
  .input-group {
    .input-group-text {
      color: #667085;
      background: transparent;
      border-color: #d0d5dd;
    }
  }
  .character {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #667085;
    margin-top: 5px;
    display: block;
  }
}

.fileUploadWrap {
  position: relative;
  height: 125px;
  background: #ffffff;
  border: 1px solid #eaecf0;
  border-radius: 8px;
  padding: 15px;
  [type="file"] {
    opacity: 0;
    cursor: pointer;
    position: absolute;
    left: 0;
    top: 0;
  }
  .upload-icon {
    width: 40px;
    height: 40px;
    background: #f2f4f7;
    border: 6px solid #f9fafb;
  }
  .fileupload-content {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #667085;
    .cornflower-blue {
      color: #6941c6;
    }
  }
}
.form-switch {
  .form-check-input {
    width: 36px;
    height: 20px;
    border-radius: 12px;
    &:checked {
      background-color: #7f56d9;
      border-color: #7f56d9;
    }
    &:focus {
      box-shadow: none;
    }
  }
}
.form-check {
  .form-check-label {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #344054;
  }
  .check {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #667085;
    min-height: auto;
    margin-bottom: 0;
  }
}
.fileupload-buttons {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #d0d5dd !important;
  margin-top: 8px;
  .file-delete {
    cursor: pointer;
    &.enable {
      color: #7f56d9;
    }
  }
  .fileupdate {
    position: relative;
    display: inline-block;
    [type="file"] {
      opacity: 0;
      position: absolute;
      left: 0;
      width: 100%;
      display: inline-block;
      top: 0;
    }
    &.enable {
      color: #7f56d9;
    }
  }
}

.add-accordion {
  .accordion-item {
    border: none;
    &:first-of-type {
      .accordion-button {
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
      }
    }
    &:last-of-type {
      .accordion-button {
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
      }
    }
    .accordion-body {
      background-color: #f9fafb;
      margin-top: 12px;
      border-radius: 8px;
    }
  }
  .accordion-button {
    background-color: transparent;
    border: 2px solid #7f56d9;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
    padding: 8px 15px;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #7f56d9;
    &::before {
      content: "\e59e";
      font-family: "Font Awesome 6 Pro";
      font-weight: 800;
      margin-right: 12px;
      width: 13px;
    }
    &:not(.collapsed) {
      &::before {
        content: "\f068";
      }
    }
    &::after {
      display: none;
    }
    &:focus {
      box-shadow: none;
    }
  }
}

.experience-form {
  .fileUploadWrap {
    height: 305px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}
.select-wrapper {
  position: relative;
  .css-319lph-ValueContainer {
    padding-left: 30px;
  }
  .country-flags {
    position: absolute;
    top: 46%;
    border-radius: 50%;
    left: 10px;
    transform: translateY(46%);
  }
}
.fileupload-process {
  background: #ffffff;
  border: 1px solid #eaecf0;
  border-radius: 8px;
  padding: 15px;
  .file-name {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #344054;
  }
  .file-size {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #667085;
  }
  .fileupload-left-icon {
    color: #7f56d9;
    width: 32px;
    height: 32px;
    background: #f4ebff;
    border: 4px solid #f9f5ff;
    border-radius: 28px;
    font-size: 15px;
  }
  .progress {
    height: 8px;
    background: #eaecf0;
    border-radius: 4px;
    width: 95%;
    margin-right: 12px;
    .progress-bar {
      background: #7f56d9;
      border-radius: 4px;
    }
  }
  .fileupload-icons-right {
    .fa-circle-check {
      color: #7f56d9;
    }
  }
  .Progressbar-parentage {
    width: 10%;
    font-weight: 500;
    font-size: 14px;
    line-height: 12px;
    color: #344054;
  }
}
.accordion-bottom-section {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #d0d5dd;
  .enable {
    color: #7f56d9;
    cursor: pointer;
  }
}

.updrade-plan-modal {
  &.modal {
    background: rgba(52, 64, 84, 0.6);
    backdrop-filter: blur(40px);
  }
  .modal-dialog {
    width: 100%;
    max-width: 480px;
  }
  .modal-header {
    padding: 24px;
    border-bottom: none;
    padding-bottom: 0;
    .btn-close {
      color: #667085;
      &:hover,
      &:focus {
        color: #667085;
        opacity: 0.5;
      }
      &:focus {
        box-shadow: none;
      }
    }
    .modal-title {
      font-weight: 500;
      font-size: 18px;
      line-height: 22px;
      color: #101828;
      margin-bottom: 5px;
    }
    .modal-discription-area {
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      color: #667085;
    }
  }
  .modal-body {
    padding: 20px 24px 0 24px;
    padding-bottom: 0;
    .membership {
      background: #f9fafb;
      border: 1px solid #eaecf0;
      border-radius: 6px;
      padding: 35px 15px;
      text-align: center;
      .membership-text {
        font-weight: 700;
        font-size: 20px;
        line-height: 24px;
        color: #b42318;
        margin-bottom: 24px;
        margin-top: 8px;
      }
      .membership-discontent {
        span {
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          color: #6941c6;
          background: #f9f5ff;
          mix-blend-mode: multiply;
          border-radius: 16px;
          padding: 4px 15px;
          display: inline-block;
        }
      }
      .membership-price {
        font-weight: 600;
        font-size: 48px;
        line-height: 52px;
        letter-spacing: -0.02em;
        color: #101828;
        margin-top: 24px;
        margin-bottom: 15px;
      }
      .membership-supporting-text {
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        color: #667085;
      }
    }
    .change-membership-plan {
      background: #f9f5ff;
      border: 1px solid #7f56d9;
      border-radius: 8px;
      padding: 16px;

      .membership-text-top {
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        color: #344054;
      }
      .membership-text-bottom {
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        color: #7f56d9;
        margin-top: 6px;
      }
      .check-icon {
        color: #7f56d9;
      }
    }
    .payment-method {
      margin-top: 32px;
      .payment-tile {
        font-weight: 500;
        font-size: 18px;
        line-height: 22px;
        color: #101828;
      }
      .payment-detail {
        margin-top: 8px;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        color: #667085;
      }
    }
    form {
      .card-number {
        position: relative;
        .form-control {
          padding-left: 55px;
        }
        .payment-icon {
          position: absolute;
          bottom: 10px;
          left: 10px;
        }
      }
      .email {
        position: relative;
        .form-control {
          padding-left: 35px;
        }
        .form-label {
          margin-bottom: 0;
        }
        .email-detail {
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: #667085;
          margin-bottom: 0.5rem;
        }
      }
      .gmail-icon {
        position: absolute;
        bottom: 10px;
        left: 10px;
        color: #667085;
        font-size: 16px;
      }
    }
    .ReactFlagsSelect-module_selectBtn__19wW7 {
      border: 1px solid #d0d5dd;
      border-radius: 8px;
      &:after {
        content: "\f107";
        border-top: none;
        border-right: none;
        border-bottom: 0;
        border-left: none;
        font-family: "Font Awesome 6 Pro";
        color: #667085;
        height: 100%;
        margin-right: 18px;
      }
    }
    hr {
      border-color: #eaecf0;
      opacity: 1;
    }
    .add-another {
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: #667085;
      cursor: pointer;
    }
  }
  .modal-footer {
    padding: 24px;
    border-top: 0;
    button {
      width: 48%;
      margin: 0;
    }
  }
}
.chat-section {
  background: #ffffff;
  border: 1px solid #eaecf0;
  box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.1),
    0px 2px 4px -2px rgba(16, 24, 40, 0.06);
  border-radius: 8px;
  overflow: hidden;
  height: calc(100vh - 285px);

  .messages-left-section {
    max-width: 360px;
    width: 100%;
    border-right: 1px solid #eaecf0;

    @media screen and (max-width: 991px) {
      max-width: 300px;
    }
  }
  .messages-edit-icon {
    background: #ffffff;
    border: 1px solid #d0d5dd;
    box-shadow: 0px 1px 2px rgb(16 24 40 / 5%);
    border-radius: 8px;
    width: 40px;
    height: 40px;
    cursor: pointer;
  }
  .messages-left-number {
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    color: #101828;
    span {
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      text-align: center;
      padding: 4px 8px;
      background: #f9f5ff;
      border-radius: 16px;
      color: #6941c6;
    }
  }
  .notification-messages-search {
    padding: 0 16px;
    margin-top: 20px;
    form {
      position: relative;
    }
    .form-control {
      padding-left: 40px;
    }
    .btn-search-icon {
      position: absolute;
      left: 0;
      color: #667085;
      top: 0;
      font-size: 16px;
      padding: 7px 12px;
      &:hover {
        border-color: transparent;
      }
    }
  }
  .notification-messages {
    height: 1033px;
    overflow: overlay;
    &::-webkit-scrollbar {
      width: 6px;
      background-color: transparent;
      border-radius: 20px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #eaecf0;
      border-radius: 20px;
    }
  }
  .messages-item-col {
    // padding: 16px 16px 16px 36px;
    padding: 16px;
    position: relative;
    border-bottom: 1px solid #eaecf0;
    &.active {
      background-color: #f9fafb;
    }
    .messages {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #667085;
    }
    .messager-name {
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: #344054;
    }
    .messager-job-title {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #667085;
    }
    .hr-time {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #667085;
    }
    .messages-img {
      position: relative;
      .mode {
        position: absolute;
        right: -1px;
        background: #d0d5dd;
        width: 11px;
        height: 11px;
        border-radius: 50%;
        border: 1.5px solid #ffffff;
        bottom: 0px;
        &.online {
          background: #12b76a;
        }
      }
    }
    .unread-message {
      background: #9e77ed;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      position: absolute;
      left: 15px;
      top: 30px;
    }
  }
  .view-profile {
    &.active {
      background-color: #f9fafb;
      min-height: 100%;
      display: flex;
      flex-direction: column;
    }
    .view-profile-header {
      padding: 20px 24px;
      border-bottom: 1px solid #eaecf0;
      background-color: #ffffff;
      position: relative;
    }
    .view-profile-name {
      font-weight: 500;
      font-size: 18px;
      line-height: 28px;
      color: #101828;
      white-space: nowrap;
    }
    .view-profile-job-title {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #667085;
    }
    .view-profile-header-img {
      position: relative;
    }
    .view-profile-header-text {
      white-space: nowrap;
    }
    .artist-verifie-tick {
      position: absolute;
      bottom: 0;
      right: 0;
    }
    .tik {
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      color: #667085;
      background: #d0d5dd;
      height: 22px;
      width: 62px;
      border-radius: 16px;
      padding: 2px 8px 2px 6px;
      text-align: right;
      position: relative;
      text-transform: capitalize;
      margin-top: 5px;
      &:before {
        content: "";
        width: 6px;
        height: 6px;
        background: #667085;
        border-radius: 50%;
        display: inline-block;
        position: absolute;
        left: 8px;
        top: 8px;
      }
      &.online {
        color: #027a48;
        background: #ecfdf3;
        &:before {
          background: #12b76a;
        }
      }
    }
    .only-files {
      background: #ffffff;
      border-radius: 0px 8px 8px 8px;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #101828;
      padding: 10px 14px;
      border: 1px solid #eaecf0;
    }
    .only-files-icon {
      background: #f4ebff;
      border: 4px solid #f9f5ff;
      border-radius: 28px;
      width: 32px;
      height: 32px;
      font-size: 14px;
      color: #7f56d9;
      text-align: center;
    }
    .file-size {
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      color: #667085;
      margin-top: 2px;
    }
    .file-name {
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      color: #344054;
    }
    .view-profile {
      background: #7f56d9;
      border-color: #7f56d9;
      height: 40px;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      max-width: 105px;
      width: 100%;
      white-space: nowrap;
    }
    .vertical-dropdown {
      margin-left: 20px;
      padding: 0;
      border: none;
      font-size: 20px;
      box-shadow: none;
      color: #98a2b3;
      cursor: pointer;
      &::after {
        display: none;
      }
    }
    .dropdown-menu {
      padding: 10px;
      left: unset !important;
      right: -4px !important;
      top: 9px !important;
      button {
        font-size: 15px;
      }
    }
  }
  .chats {
    padding-left: 32px;
    padding-right: 32px;
    padding-top: 40px;
    background: #fcfcfd;
    overflow: auto;
    flex-direction: column-reverse !important;
    height: calc(100vh - 530px);
    &::-webkit-scrollbar {
      width: 6px;
      background-color: transparent;
      border-radius: 20px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #eaecf0;
      border-radius: 20px;
    }
  }
  .send-message {
    // margin-top: auto;
    padding-left: 32px;
    padding-right: 32px;
    padding-bottom: 24px;
    padding-top: 10px;
    background: #ffffff;
    position: relative;
    form {
      position: relative;
    }
    .form-control {
      padding-right: 90px;
      overflow: overlay;
      &::-webkit-scrollbar {
        width: 6px;
        background-color: transparent;
        border-radius: 20px;
      }
      &::-webkit-scrollbar-thumb {
        background-color: #eaecf0;
        border-radius: 20px;
      }
      &:focus {
        box-shadow: none;
        border-color: #d0d5dd;
      }
    }
    .previewimage {
      position: absolute;
      z-index: 1;
      bottom: 100%;
      // width: calc(100% - 64px);
      width: auto;
      background: #fff;
      padding: 10px;
      border: 1px solid #d0d5dd;
      border-radius: 8px;
      img {
        width: 125px;
        height: 115px;
        border-radius: 5px;
      }
      .previewimage-col {
        position: relative;
        display: inline-block;
      }
      .xmark-icon {
        position: absolute;
        right: 4px;
        background: #fff;
        border: 1px solid #d0d5dd;
        top: 3px;
        display: inline-block;
        font-size: 16px;
        width: 25px;
        height: 25px;
        text-align: center;
        border-radius: 50%;
      }
    }
  }
  .online-send-message {
    background: #f2f4f7;
    border-radius: 0px 8px 8px 8px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #101828;
    padding: 10px 14px;
  }
  .now-message {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #667085;
  }
  .you-message {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #344054;
  }
  .you-send-message {
    background: #7f56d9;
    border-radius: 8px 0px 8px 8px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #ffffff;
    padding: 10px 14px;
  }
  .day-line {
    text-align: center;
    position: relative;
    &:before {
      content: "";
      background: #eaecf0;
      height: 1.5px;
      width: 100%;
      display: block;
      position: absolute;
      top: 13px;
    }
    span {
      background: #fff;
      padding: 0 8px;
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      color: #667085;
      text-transform: capitalize;
      position: relative;
    }
  }
  .chat-online,
  .chat-text-col,
  .chat-you-col {
    max-width: 565px;
    width: 100%;
  }
  .dot-typing {
    position: relative;
    left: -9999px;
    width: 5px;
    height: 5px;
    border-radius: 5px;
    box-shadow: 9984px 0 0 0 #667085, 9999px 0 0 0 #98a2b3,
      10014px 0 0 0 #667085;
    animation: dotTyping 1.5s infinite linear;
  }
  @keyframes dotTyping {
    0% {
      box-shadow: 9984px 0 0 0 #667085, 9999px 0 0 0 #98a2b3,
        10014px 0 0 0 #667085;
    }
    16.667% {
      box-shadow: 9984px -10px 0 0 #667085, 9999px 0 0 0 #98a2b3,
        10014px 0 0 0 #667085;
    }
    33.333% {
      box-shadow: 9984px 0 0 0 #667085, 9999px 0 0 0 #98a2b3,
        10014px 0 0 0 #667085;
    }
    50% {
      box-shadow: 9984px 0 0 0 #667085, 9999px -10px 0 0 #98a2b3,
        10014px 0 0 0 #667085;
    }
    66.667% {
      box-shadow: 9984px 0 0 0 #667085, 9999px 0 0 0 #98a2b3,
        10014px 0 0 0 #667085;
    }
    83.333% {
      box-shadow: 9984px 0 0 0 #667085, 9999px 0 0 0 #98a2b3,
        10014px -10px 0 0 #667085;
    }
    100% {
      box-shadow: 9984px 0 0 0 #667085, 9999px 0 0 0 #98a2b3,
        10014px 0 0 0 #667085;
    }
  }
  .typing-message-loader {
    background: #f2f4f7;
    border-radius: 0px 8px 8px 8px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #101828;
    padding: 15px;
    display: flex;
    justify-content: center;
    width: 60px;
    height: 35px;
  }
}

.home-loader {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  .loading-text {
    font-size: 15px;
    line-height: 19px;
    color: #667085;
    margin-top: 5px;
    display: block;
  }
}

.chat-section {
  .messages-left-section {
    max-width: 360px;
    width: 100%;
    border-right: 1px solid #eaecf0;
    -webkit-transition: width 1s ease-in-out;
    transition: width 1s ease-in-out;
    transition-delay: 0s;
    overflow: hidden;
  }
  .messages-left-section-hide {
    width: 0;
    max-width: 360px;
    overflow: hidden;
    -webkit-transition: width 1s ease;
    transition: width 1s ease;
    transition-delay: 0s;
    border-right: 1px solid #fff;
  }
}
.side-panel-profile {
  max-width: 360px;
  width: 100%;
  -webkit-transition: width 1s ease-in-out;
  transition: width 1s ease-in-out;
  transition-delay: 0s;
}
.side-panel-profile-hide {
  width: 0;
  max-width: 360px;
  overflow: hidden;
  -webkit-transition: width 1s ease;
  transition: width 1s ease;
  transition-delay: 0s;
}
.back-massage-button {
  background: #f9f5ff;
  border: 1px solid #f9f5ff;
  box-shadow: 0px 1px 2px rgb(16 24 40 / 5%);
  border-radius: 8px;
  color: #6941c6;
  width: 40px;
  height: 40px;
  line-height: 21px;
}
.side-profile-col {
  .left-profile-button {
    -webkit-transition: width 1s ease-in-out;
    transition: width 1s ease-in-out;
    transition-delay: 0s;
    margin-right: 15px;
    max-width: 40px;
    width: 100%;
    margin-top: auto;
    margin-bottom: auto;
    position: absolute;
    left: 10px;
    top: 0;
    z-index: 1;
    display: none;
  }
  .back-massage-button {
    display: none;
  }
}
.left-profile-button-hide {
  width: 0;
  overflow: hidden;
  -webkit-transition: width 1s ease;
  transition: width 1s ease;
  transition-delay: 0s;
  margin-top: auto;
  margin-bottom: auto;
}
.view-profile-header-right-section {
  -webkit-transition: width 1s ease-in-out;
  transition: width 1s ease-in-out;
  transition-delay: 0s;
  width: 100%;
  max-width: 105px;
  margin-left: auto;
}
.view-profile-header-right-section-hide {
  width: 0;
  overflow: hidden;
  -webkit-transition: width 1s ease;
  transition: width 1s ease;
  transition-delay: 0s;
  display: flex;
  justify-content: flex-end;
  align-items: self-end;
}
.side-profile-col {
  background-color: #ffffff;
  margin-left: 30px;
  box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.1),
    0px 2px 4px -2px rgba(16, 24, 40, 0.06);
  border-radius: 8px;
  overflow-y: scroll;
  height: calc(100vh - 270px);
  position: relative;
  &::-webkit-scrollbar {
    width: 6px;
    background-color: transparent;
    border-radius: 20px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #eaecf0;
    border-radius: 20px;
  }
  .side-profile-top {
    position: relative;
  }
  .artist-verifie-tick {
    position: absolute;
    top: 12px;
    right: 12px;
  }
  .side-profile-social-media {
    padding: 20px;
    display: block;
    background: linear-gradient(0deg, #fcfcfd, #fcfcfd), rgba(0, 0, 0, 0.3);
    border: 1px solid #eaecf0;
    backdrop-filter: blur(24px);
    border-left: 0;
    border-right: 0;
    overflow-x: scroll;
    white-space: nowrap;
    &::-webkit-scrollbar {
      height: 6px;
      background-color: transparent;
      border-radius: 20px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #eaecf0;
      border-radius: 20px;
    }
    a {
      margin-right: 24px;
      display: inline-block;
      &:last-child {
        margin-right: 0;
      }
    }
  }
  .side-profile-bottom {
    padding: 24px 24px 17px 24px;
    .side-profile {
      font-weight: 500;
      font-size: 24px;
      line-height: 32px;
      color: #101828;
      display: inline-block;
      padding-right: 15px;
      position: relative;
      text-transform: capitalize;
      .mode {
        background: #12b76a;
        border: 1.5px solid #ffffff;
        border-radius: 5px;
        width: 10px;
        height: 10px;
        display: inline-block;
        position: absolute;
        right: 0;
        top: 10px;
      }
    }
    .side-profile-job-title {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #667085;
      text-transform: capitalize;
    }
    .buttons {
      button {
        width: 47%;
        height: 40px;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
      }
    }
    .tracker {
      border-bottom: 1px solid #eaecf0;
      span {
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        color: #667085;
      }
    }
    .description-experience {
      .description-title {
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: #344054;
      }
      .description {
        font-weight: 400;
        font-size: 14px;
        line-height: 19px;
        color: #667085;
      }
      .experience-title {
        font-weight: 500;
        font-size: 14px;
        line-height: 19px;
        color: #344054;
      }
      .experience-field {
        padding-left: 10px;
        border-left: 1px solid #eaecef;
        border-radius: 2px;
      }
      .experience-field-title {
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        color: #6941c6;
      }
      .experience-field-list {
        .actor {
          background: #f9f5ff;
          color: #6941c6;
        }
        .producer {
          background: #eff8ff;
          color: #175cd3;
        }
        .director {
          color: #3538cd;
          background: #eef4ff;
        }
        span {
          border-radius: 16px;
          font-weight: 500;
          font-size: 12px;
          line-height: 18px;
          padding: 4px 8px;
        }
      }
    }
  }
}
#card-number,
#credit-card-number,
#cvv,
#expiration-date,
#expiration,
#card-name {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  height: 40px;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  appearance: none;
  border-radius: 0.375rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.btn-outline-primary:hover {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: #b42318;
}
.error-div {
  background: #b42318;
  display: flex;
  justify-content: space-between;
  padding: 3%;
  margin-bottom: 4%;
  color: #fff;
}
.award-titles {
  font-weight: 600;
  font-size: 16px;
  // text-align: center;
  line-height: 28px;
  margin-top: 40px;
  .award_tabs {
    display: inline-block;
    padding: 5px;
    border-radius: 10px;
    border: 1px solid rgba(208, 213, 221, 0.29);
    .card {
      display: inline-block;
      margin: 5px;
      padding: 6px 19px;
      border: 1px solid #d0d5dd;
      border-radius: 6px;
      &.card-award-active {
        background: #b42318;
        color: #fff;
        border-color: #b42318;
      }
      &:hover {
        background: #b42318;
        color: #fff;
        border-color: #b42318;
      }
    }
  }
}

.subscription-custom-modal .banner-title-content {
  background: none !important;
  border: none !important;
}
.h-78 {
  height: 782px !important;
}
.w-45 {
  width: 45% !important;
}
.b-20 {
  bottom: 20% !important;
}
.video-item-col {
  iframe {
    width: 100%;
  }
}

.loaction-class {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
/*---------------------*/
.account-profile-menu-top {
  .banner-search {
    position: relative;
    max-width: 495px;
    width: 100%;
    input {
      background: #344054;
      border: 1px solid #344054;
      color: #ffffff;
      padding-left: 40px;
      width: 100%;
      font-weight: 400;
      font-size: 15px;
      line-height: 18px;
      &::placeholder {
        color: #ffffff;
      }
      &:-ms-input-placeholder {
        color: #ffffff;
      }
      &::-ms-input-placeholder {
        color: #ffffff;
      }
      &:focus {
        box-shadow: 0 0 0 0.25rem rgb(52 64 84 / 25%);
      }
    }
    .search-icon {
      position: absolute;
      left: 12px;
      top: 50%;
      transform: translateY(-50%);
      cursor: pointer;
    }
    .suggestions .suggestions-img {
      img {
        width: 40px;
        height: 40px;
      }
    }
    .suggestions {
      .suggestions-director {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #667085;
      }
      .suggestions-name {
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
      }
    }
    .suggestions {
      border: 1px solid #eaecf0;
      border-radius: 5px;
      box-shadow: 0 12px 16px -4px rgb(16 24 40 / 8%),
        0 4px 6px -2px rgb(16 24 40 / 3%);
      max-height: 300px;
      max-width: 495px;
      // min-height: 300px;
      padding: 15px 8px 15px 15px;
      width: 100%;
      overflow-y: hidden;
      margin-top: 12px;
      z-index: 9;
      li {
        min-height: 58px;
        margin-bottom: 15px;
        height: 100%;
        &:not(:last-of-type) {
          border: none;
          border-bottom: 1px solid #eaecf0;
        }
      }
    }
    .no-suggestions {
      width: 100%;
      text-align: center;
      margin-top: 12px;
      border: 1px solid #eaecf0;
      border-radius: 5px;
      box-shadow: 0 12px 16px -4px rgb(16 24 40 / 8%),
        0 4px 6px -2px rgb(16 24 40 / 3%);
    }
  }
}

.banner-search-col {
  position: absolute;
  bottom: 140px;
  left: 0;
  right: 0;
  padding: 0 10px;
}

.signup-hight {
  height: 100vh;
}

.tracker {
  .tracker-left {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;

    .tracker-icon {
      position: absolute;
      height: 50px;
      display: flex;
      align-items: center;
    }
  }
  .tracker-details {
    padding-left: 30px;
  }
  .tracker-right {
    position: relative;
    width: 100%;
    display: flex;
    .tracker-icon {
      position: absolute;
      height: 50px;
    }
  }
}

@media screen and (max-width: 992px) {
  .chat-section .messages-left-section {
    max-width: 280px;
  }
}

.artist-img img {
  aspect-ratio: 1 / 1;
}

.artist-top-col {
  .artist-img {
    img {
      object-fit: cover;
      object-position: top;
    }
  }
}

// 10-10-2022
.artist-about-button {
  .tracker-details {
    padding-left: 30px;
    display: flex;
  }
  .tracker-left {
    position: relative;
    // width: 100%;
    .tracker-icon {
      position: absolute;
      height: 50px;
      width: 22px;
    }
  }
  .tracker-right {
    position: relative;
    // width: 100%;
    .tracker-icon {
      position: absolute;
      height: 50px;
      width: 22px;
    }
  }
}

.view-profile-header-left {
  align-items: center;
}

.required {
  content: "*";
  color: #dc3545;
}

@media only screen and (min-width: 992px) and (max-width: 1360px) {
  .authentication-page {
    .signup-box {
      margin-top: 60px;
      .already-login-line {
        margin-top: 0.5rem;
      }
    }
  }
}

/*13*-10-2022*/
footer {
  .footer-logo {
    margin-bottom: 24px;
  }
  .footer-col {
    width: 40%;
    display: inline-block;
    padding-right: 10px;
    .footer-title {
      font-size: 16px;
      color: #ffffff;
    }
    &.contact-form-col {
      width: 60%;
      padding-right: 0;
      .form-control {
        &.error {
          border-color: #db3700;
        }
        border: 1px solid #353e51;
        border-radius: 5px;
        background-color: transparent;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        &:focus {
          color: #667085;
          box-shadow: none;
        }
        &.textarea {
          height: 128px;
        }
      }
      .text-danger.form-text {
        font-size: 12px;
      }
      .btn-primary {
        width: 136px;
        text-transform: capitalize;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
      }
    }
  }
}
/*14-10-2022*/
.subscription {
  .footer-top {
    padding-top: 130px;
    margin-top: 0;
  }
  footer {
    .footer-newsletter {
      padding-top: 0;
      margin-top: 0;
    }
  }
}
.footer-newsletter {
  border-bottom: 1px solid #353e51;
  padding-bottom: 30px;
  margin-bottom: 30px;
  .newsletter {
    .newsletter-title {
      font-weight: 600;
      font-size: 20px;
      line-height: 20px;
      text-transform: capitalize;
      color: #fff;
    }
    .email {
      max-width: 425px;
      .form-control {
        height: 48px;
        border-color: #dddddd;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        background: #fff;
        padding-left: 30px;
        padding-right: 30px;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        text-transform: capitalize;
        color: #667085;
        width: 100%;
      }
    }
    .btn-primary {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      padding-left: 20px;
      padding-right: 20px;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      height: 48px;
    }
  }
}
@media only screen and (max-width: 480px) {
  footer {
    .footer-newsletter {
      .newsletter {
        .email {
          .form-control {
            padding-left: 12px;
            padding-right: 12px;
            font-size: 14px;
          }
        }
        .btn-primary {
          padding-left: 15px;
          padding-right: 15px;
          font-size: 14px;
        }
      }
    }
  }
}

.paginations {
  .pagination-page-number {
    font-weight: 400;
    font-size: 15px;
    line-height: 19px;
    color: #667085;
  }
  .pagination-item {
    button {
      border: 0;
      border-radius: 50%;
      height: 40px;
      width: 40px;
      font-size: 16px;
      font-weight: 500;
      color: #757575;
      background: transparent;
      -webkit-transition: 0.4s;
      transition: 0.4s;
      &.disabled {
        cursor: unset;
        color: rgba(0, 0, 0, 0.18);
        &:hover {
          background-color: transparent;
        }
      }
      &:hover {
        background-color: rgba(0, 0, 0, 0.12);
      }
    }
  }
}

.artist-video-slider {
  .slick-prev {
    left: 10px;
    &::before {
      content: "\f104";
      font-family: "Font Awesome 6 Pro";
      font-size: 20px;
      color: #ffffff;
      line-height: 1.2;
    }
  }
  .slick-next {
    right: 10px;
    &::before {
      content: "\f105";
      font-family: "Font Awesome 6 Pro";
      font-size: 20px;
      color: #ffffff;
      line-height: 1.2;
    }
  }
  .slick-prev,
  .slick-next {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    transition: 0.4s all;
    // border: 1px solid rgba(0, 0, 0, 0.5);
    // background-color: rgba(0, 0, 0, 0.3);
    border: 1px solid #fff;
    background-color: #b42318;
    z-index: 1;
    padding: 2.4px 8px;
    // white-space: unset;
    line-height: 1.2;
    &::before {
      opacity: 1;
    }
    &.slick-disabled {
      opacity: 0;
    }
    &:hover,
    &:focus {
      border: 1px solid #b42318;
      background-color: #b42318;
    }
  }
  .banner-item {
    iframe {
      width: 100%;
      height: 230px;
    }
  }
}

.red-rider {
  border-bottom: 1px solid #eaecf0;
  position: relative;
  &:last-child {
    border: none;
  }
  .edit-icon {
    position: absolute;
    top: 0;
    right: 0;
    button {
      width: auto;
      height: 34px;
      font-weight: 500;
      font-size: 12px;
      line-height: 1.2;
    }
  }
  .job-list-left-col {
    label {
      background-color: transparent;
      // text-transform: capitalize;
    }
  }
}
.custom-load-more_global {
  font-size: 14px;
  text-decoration: underline;
  color: #b42318 !important;
}
.payment-icon {
  position: absolute !important;
  right: 3%;
  top: 20%;
}
.skl-faq {
  margin-left: 32px;
  margin-top: 32px;
  margin-right: 32px;
  border-radius: 15px !important;
}
.artist-profile.video-profile .ctm-behind-slider {
  .video-artist-bottom-img .artist-img {
    height: auto !important;
  }
  .slick-slide {
    height: 360px;
    *,
    iframe {
      height: 100% !important;
    }
  }
}
@media only screen and (min-width: 1800px){
  .artist-profile.video-profile .ctm-behind-slider .slick-slide{
    height: 470px;
  }
}
// 10-03-2023
@media only screen and (max-width: 767px){
  .artist-profile .artist-bottom-col .artist-img {
    height: 275px;
    iframe{
      height: 100%;
    }
  }
  .artist-profile.video-profile .ctm-behind-slider .slick-slide {
    height: 275px;
  }
}
@media only screen and (max-width: 480px){
  .artist-profile .artist-bottom-col .artist-img {
    height: 245px;
    iframe{
      height: 100%;
    }
  }
  .artist-profile.video-profile .ctm-behind-slider .slick-slide {
    height: 245px;
  }
}
@media only screen and (max-width: 390px){
  .artist-profile .artist-bottom-col .artist-img {
    height: 205px;
    iframe{
      height: 100%;
    }
  }
  .artist-profile.video-profile .ctm-behind-slider .slick-slide {
    height: 205px;
  }
}