.table-add-inn .form-check-input {
  width: 20px;
  height: 20px;
}

.btnSec {
  gap: 15px;
  div {
    width: 100%;

    button {
      width: 100%;
      &.ms-3 {
        margin-left: 0 !important;
      }
    }
  }
}
.mobile-search {
  button.btn.btn-search-icon {
    position: absolute;
    line-height: normal;
  }
  input.form-control {
    padding-left: 40px;
  }
}
.mobile-main-head {
  background: #101828;
}
// .mobile-select{
//         select {
//             outline: 0;
//             background: #fff;
//             color: #101828;
//             border: 1px solid #d0d5dd;
//             padding: 4px 25px 4px 10px;
//             border-radius: 9px;
//             width: 100%;
//             height: 100%;
//          }
// }
@media only screen and (max-width: 1399px) {
  .upgrade-plan-section .plan-price .access-list {
    min-height: 350px;
  }
  .artist-community {
    .title-artist-community {
      font-size: 42px;
    }
  }
  .artist-about-button {
    .tracker-right {
      margin-top: 10px;
    }
    &.address-bottom-col {
      flex-direction: column;
      align-content: flex-start;
    }
  }
}

@media only screen and (max-width: 1299px) {
}

@media only screen and (max-width: 1199px) {
  .job-profile-modal {
    .form-button {
      button,
      button.transparent-btn.btn.btn-primary {
        line-height: 18px;
      }
      .transparent-btn.btn.btn-primary {
        font-size: 16px;
      }
    }
  }
  footer {
    .footer-top {
      padding-top: 40px;
      padding-bottom: 40px;
      margin-top: 55px;
    }

    .footer-logo {
      margin-bottom: 15px;
    }

    .footer-discription-area {
      margin-bottom: 20px;
    }

    .footer-col {
      a {
        font-size: 14px;
        line-height: 18px;
      }

      label {
        font-size: 9px;
        line-height: 13px;
        padding: 2px 5px;
      }
    }
  }

  .account-profile-menu {
    .messages-col {
      font-size: 14px;
      padding-left: 0;
    }

    .notification-tab {
      font-size: 14px;
      margin-left: 0px !important;
    }

    .nav-link {
      padding: 10px 10px 10px 10px;
      font-size: 14px;
    }
  }

  .artist-community {
    button {
      &.slick-arrow.slick-prev {
        bottom: 45px !important;
      }

      &.slick-arrow.slick-next {
        bottom: 45px !important;
      }
    }
  }

  .main-content {
    .cards-class-all {
      margin-top: 55px;
    }
  }
  .artist-community {
    div {
      button.transparent-btn.btn.btn-primary {
        font-size: 16px;
        line-height: 24px;
      }
      button.btn.btn-primary {
        font-size: 16px;
        line-height: 24px;
      }
    }
  }
  .artist-profile {
    .tracker span {
      font-size: 13px;
    }
    .artist-top-col {
      .artist-name {
        font-size: 30px;
      }
    }
  }
}

@media only screen and (max-width: 1023px) {
  .upgradaline {
    align-items: flex-start !important;
  }

  .upgrade-plan-section {
    .plan-price {
      .access-list {
        min-height: 280px;
      }

      .monthly-price {
        font-size: 40px;
      }

      .planespace {
        margin-bottom: 30px;
      }
    }
  }

  button.transparent-btn.btn.btn-primary {
    font-size: 16px !important;
  }

  .chat-section {
    .view-profile {
      .view-profile-header-right-section {
        margin-left: 0;
      }

      .view-profile-name {
        font-size: 16px;
      }

      .chats {
        padding-left: 15px;
        padding-right: 15px;
      }

      .send-message {
        padding-left: 15px;
        padding-right: 15px;
      }
    }

    .view-profile-header {
      .left-profile-button {
        margin-right: 10px;
      }
    }

    .mobile-select {
      select {
        outline: 0;
        color: #101828;
        border: 1px solid #d0d5dd;
        padding: 4px 25px 4px 10px;
        border-radius: 9px;
        width: 100%;
        height: 100%;
        appearance: none;
        background: #fff url(../media/icons/btn-icon.png) no-repeat center right
          10px;
        line-height: 30px;
      }
    }
  }
  .chat-section {
    .view-profile {
      .view-profile-header {
        padding: 18px 15px;
        gap: 20px;
      }
    }
  }
  .btnSec {
    .removeBtn {
      button {
        padding: 11px 30px;
      }
    }
  }
  .btnSec {
    div {
      button {
        padding: 11px 30px;
      }
    }
  }
  .created-page button.transparent-btn.btn.btn-primary {
    padding: 9px 25%;
  }
  .job-profile-modal {
    .form {
      padding: 1rem !important;
      &.mt-3 {
        margin-top: 0 !important;
      }
    }
    .form-button {
      flex-direction: column;
      gap: 12px;
      padding: 1rem !important;
      button,
      button.transparent-btn.btn.btn-primary {
        font-size: 14px;
        width: 100%;
      }
    }
  }
  .view-details-modal {
    .modal-body {
      padding-left: 5px;
      padding-right: 5px;
      .details-section {
        .job-detail-button {
          button.transparent-btn.btn.btn-primary {
            font-size: 12px !important;
          }
        }
        .details-col {
          div {
            .details-col-title {
              font-size: 12px;
            }
            .details-col-job-title {
              font-size: 12px;
            }
          }
        }
      }
    }
    .modal-header {
      padding: 25px 15px 25px 15px;
      .title {
        font-size: 20px;
      }
    }
  }
  .job-profile-modal {
    .modal-content {
      padding: 16px;
    }
    .modal-body {
      .account-profile-col {
        form {
          .fileUploadWrap {
            height: auto;
          }
          .add-accordion {
            .accordion-button {
              font-size: 13px;
            }
          }
        }
      }
    }
  }
  // .footer-top {
  //   .footer-inner {
  //     .footer-col {
  //       width: 33%;
  //       margin-bottom: 15px;
  //     }
  //   }
  // }
  .artist-profile {
    .tracker {
      flex-flow: wrap;
      gap: 15px;
    }
    .tracker span {
      font-size: 14px;
    }
  }
}

@media only screen and (max-width: 990px) {
  .artist-profile {
    .artist-job-col {
      .job-bottom-title {
        font-size: 28px;
        line-height: 34px;
      }
    }
  }
}

@media only screen and (max-width: 991px) {
  .plan-price {
    margin-bottom: 60px;
    margin-top: -65px;
    .monthly-price {
      font-size: 36px;
      line-height: 44px;
      margin-top: 20px;
    }
  }
  .support-section {
    padding: 50px 0;
  }
  .faqs-section {
    padding-top: 50px;
    padding-bottom: 50px;
    .faq-accordion {
      .accordion-item {
        margin-bottom: 20px;
      }
      .accordion-button {
        padding-left: 20px;
        padding-top: 20px;
        padding-right: 20px;
      }
    }
  }
  .faqs-section {
    .faq-title {
      font-size: 30px;
      line-height: 38px;
    }
  }
  .subscription {
    .footer-top {
      padding-top: 150px;
    }
  }
  .today-free-section {
    .today-free-col {
      padding: 20px;
    }
  }
  .offcanvas-body {
    padding-top: 5px;
  }

  .offcanvas-header {
    border-bottom: 1px solid #eaecf0;
  }

  button.btn.btn-base-button {
    padding-left: 0;
  }

  .navbar-toggler-icon {
    background: url(../media/icons/manu-icon.svg) no-repeat center;
  }

  .btn-close {
    background: url(../media/icons/manucross.svg) center/1em auto no-repeat;
    opacity: 1;
  }

  .navbar-toggler {
    padding: 0;
  }

  footer .footer-icon {
    font-size: 20px;
  }

  .top-banner .slick-prev,
  .top-banner .slick-next {
    display: none !important;
  }

  .artist-community {
    button {
      &.slick-arrow.slick-prev {
        height: 30px;
        width: 30px;
        background-size: 12px;
        bottom: 35px;
      }

      &.slick-arrow.slick-next {
        height: 30px;
        width: 30px;
        background-size: 12px;
        bottom: 35px;
      }
    }
  }

  .artist-community {
    .artist-community-banner {
      .artist-name {
        font-size: 19px !important;
      }
    }
  }

  .page-top-banner .banner-title-content {
    font-size: 40px;
  }

  .top-banner {
    .banner-content {
      .banner-discription-area {
        margin-bottom: 30px;
      }
    }
  }
  .Awards-page-main button.btn.btn-primary {
    font-size: 16px;
  }

  .videos .title {
    font-size: 32px;
    align-items: center;
  }

  .account-profile .account-title,
  .created-page .title-class-add {
    font-size: 26px;
    line-height: 30px;
    align-items: center;
  }

  .cards-class-all button.btn.btn-primary {
    font-size: 14px;
    line-height: 1.3;
  }

  .Billing-page-main {
    table {
      width: 800px;
    }

    .tabspace {
      margin-bottom: 20px;
    }
  }
  .view-details-modal {
    .modal-dialog {
      max-width: 96%;
    }
  }
  .mobile-select {
    select {
      outline: 0;
      color: #101828;
      border: 1px solid #d0d5dd;
      padding: 4px 25px 4px 10px;
      border-radius: 8px;
      width: 100%;
      height: 100%;
      appearance: none;
      background: #fff url(../media/icons/btn-icon.png) no-repeat center right
        10px;
      line-height: 30px;
    }
  }
}

@media only screen and (max-width: 990px) {
  // .offcanvas.offcanvas-end {
  //   width: 85vw;
  // }
}

@media only screen and (max-width: 800px) {
  footer {
    .footer-icons {
      margin-top: 10px;
    }
    .footer-top {
      margin-top: 40px;
    }
  }

  .top-banner .banner-discription-area {
    font-size: 18px;
    line-height: 23px;
    margin-bottom: 30px;
  }

  .page-top-banner {
    .banner-title-content {
      font-size: 30px;
      line-height: 55px;
      padding-bottom: 25px;
      margin-bottom: 15px;
    }

    .discription-content-area {
      font-size: 18px;
      line-height: 23px;
    }
  }

  .artist-community {
    margin-top: 45px;
  }

  .Billing-page-main .title-class-add-new-two {
    flex-direction: column;
    gap: 20px;
  }

  .upgrade-plan-section {
    .plan-price {
      .monthly-price {
        font-size: 26px;
        line-height: 30px;
      }
    }

    .upgrade-title {
      font-size: 26px;
      line-height: 31px;
    }
  }

  .chat-section-col {
    width: 0 !important;
    box-shadow: none;
    border: none;
  }
  .left-profile-button {
    position: absolute;
    top: 0;
    z-index: 1;
  }
  .side-panel-profile {
    max-width: 100%;

    .side-profile-col {
      //   margin-left: 0;
      //   box-shadow: none;
      //   border: none;
      border-radius: 0;
      margin-left: 0;
      box-shadow: none;
      border: none;
      padding-top: 60px;
      overflow: overlay;
      height: 840px;
    }
  }

  .chat-section .messages-item-col {
    padding: 16px 16px 16px 16px;
  }

  .chat-section {
    // height: auto;
    // flex-direction: column;
    position: relative;
    flex-direction: column;
    // position: absolute;
    width: 100%;
    left: 0;
    box-shadow: none;
    border: none;

    .messages-left-section {
      max-width: 100%;
      overflow: visible;
    }

    .MobileView {
      position: absolute;
    }

    .notification-messages .messages-item-col .unread-message {
      left: 7px;
    }
  }
  .left-profile-button-hide {
    margin-top: unset;
    margin-bottom: 10px;
  }
  //   .side-panel-profile {
  //     .side-profile-top {
  //       margin-top: 5px;
  //     }
  //   }
  .side-profile-col {
    margin-left: 0;
  }
  .chat-section {
    .view-profile {
      .view-profile-close {
        padding: 18px 15px;
      }

      .view-profile-header {
        flex-direction: initial;
        gap: 0;
      }

      .send-message {
        .form-control {
          height: 64px;
        }
      }
    }
  }

  .chat-section .messages-item-col .unread-message {
    left: 7px;
    display: none;
  }
  // .side-profile-col {
  //   .left-profile-button,
  //   .left-profile-button-hide {
  //     display: none;
  //   }
  // }
}

@media only screen and (max-width: 767px) {
  .artist-about-button {
    &.address-bottom-col {
      flex-direction: column;
      align-content: flex-start;
    }
  }
  .side-profile-col {
    .left-profile-button {
      display: block;
    }
    .back-massage-button {
      display: block;
    }
  }
  .artist-profile {
    .tracker {
      margin-top: 15px !important;
    }
    .video-title,
    .video-title-link {
      font-size: 16px;
      line-height: 28px;
    }
  }
  .mobile-view-header {
    background-color: #ffffff;
    padding-left: 16px;
    padding-right: 12px;
    border-bottom: 1px solid #f2f4f7;
  }
  .closeButton {
    padding: 17px 0px;
    box-shadow: none;
    button {
      background-size: 12px;
      width: 38px;
      height: 38px;
      padding: 0;
      background-color: #f9fafb;
      border-radius: 8px;
    }
  }

  .chat-section {
    .MobileView {
      position: fixed;
      width: 100vw;
      height: 100vh;
      top: 0;
      left: 0;
    }
  }

  .side-panel-profile,
  .side-panel-profile-hide {
    max-width: 100%;
    position: fixed;
    left: 0;
    top: 0;
    .left-profile-button,
    .left-profile-button-hide {
      button {
        margin-top: 10px;
        margin-left: 10px;
      }
    }

    .side-profile-top {
      img {
        object-fit: cover;
        object-position: top center;
      }
    }
  }

  .updrade-plan-modal {
    .modal-body {
      .membership {
        .membership-price {
          font-size: 36px;
          line-height: 40px;
        }
      }
    }
  }
  .job-profile-modal {
    .modal-dialog {
      max-width: 96%;
    }
  }
  .view-details-modal {
    .modal-header {
      .title {
        font-size: 24px;
      }
    }
    .modal-body {
      padding-left: 25px;
      padding-right: 25px;
      .details-col {
        padding: 15px 10px;
      }
    }
  }
  .account-profile {
    .notification-list {
      padding: 16px 16px;
    }
  }
  .artist-community {
    button.slick-arrow.slick-next,
    button.slick-arrow.slick-prev {
      bottom: 40px !important;
    }
    .title-artist-community {
      font-size: 30px !important;
      line-height: 36px;
    }
  }
  .tracker {
    margin-top: 20px !important;
  }
  .artist-profile button {
    height: 40px;
  }
  .artist-profile {
    .star-sharp {
      .start-un-favour {
        font-size: 20px;
      }
    }
  }
  .artist-profile {
    .artist-job-col {
      padding: 28px;
    }
  }
}

@media only screen and (max-width: 575px) {
  .top-banner {
    // .slider-img {
    //   height: 440px;
    // }

    .slider-img {
      height: 330px;
    }

    // .slick-track {
    //   top: 20%;
    // }
  }

  .page-top-banner img {
    height: 440px;
  }

  .Billing-page-main {
    .switch-and-billing {
      .text-muted {
        text-align: left;
      }
    }
  }

  .upgrade-plan-section ul li {
    font-size: 14px;
  }

  .plan-price .plan-card {
    padding: 23px;
  }

  .updrade-plan-modal {
    .modal-dialog {
      max-width: 96%;
    }
  }
  .job-profile-modal {
    .form-button {
      display: flex;
    }
  }
  .fileUploadWrap {
    .fileupload-content {
      line-height: 16px;
    }
  }
  .job-profile-modal {
    form {
      .creatjobpro {
        flex-direction: column;
        gap: 20px;
        .jobuplord {
          width: 100%;
          .fileUploadWrap {
            height: 100%;
          }
        }
      }
    }
    .modal-body {
      .job-description-section {
        .details-col {
          flex-direction: column;
          gap: 20px;
        }
      }
    }
  }
  .artist-community {
    .title-artist-community {
      font-size: 24px !important;
      line-height: 36px;
      margin-bottom: 6% !important;
    }
  }
  .artist-profile {
    .video-title-link {
      font-size: 14px;
    }
  }
}

@media only screen and (max-width: 480px) {
  .slider-img {
    height: 475px;
  }
  .offcanvas-body button.btn.btn-login-button {
    margin: 0px 3% 0 2% !important;
  }

  // footer .footer-col {
  //   width: 50%;
  // }

  // .artist-community button.slick-arrow.slick-prev::before,
  // .artist-community button.slick-arrow.slick-next::before {
  //   display: none !important;
  // }

  .page-top-banner {
    .banner-title-content {
      font-size: 24px;
      line-height: 34px;
      padding-bottom: 15px;
    }
    .banner-search-col {
      bottom: 130px;
    }
    .discription-content-area {
      font-size: 16px;
      line-height: 23px;
    }
  }

  .Awards-page-main {
    .title {
      font-size: 20px !important;
    }

    button.btn.btn-primary {
      line-height: 20px;
    }
  }

  .account-profile-section {
    .fileUploadWrap {
      height: 150px;
    }
    .job-profile-modal {
      form {
        .creatjobpro {
          flex-direction: column;
          gap: 20px;
          .jobuplord {
            width: 100%;
            .fileUploadWrap {
              height: 100%;
            }
          }
        }
      }
      .modal-body {
        .job-description-section {
          .details-col {
            flex-direction: column;
            gap: 20px;
          }
        }
      }
    }
  }

  .fileupload-content {
    font-size: 13px;
    line-height: 19px;
  }

  .profilemob {
    flex-direction: column;
    gap: 20px;
  }
  .profullwith {
    width: 100%;
  }
  .artist-profile {
    .artist-left-discription {
      font-size: 14px !important;
      line-height: 22px;
    }
    .artist-job-col {
      padding: 25px;
    }
    .artist-job-col {
      .job-bottom-title {
        font-size: 22px !important;
        margin-top: 3px;
      }
    }
  }
}

.account-profile {
  .infinite-scroll-component {
    min-height: unset;
    max-height: unset;
  }
}

.job-profile-modal {
  .job-description-section {
    .location-details-icon {
      position: absolute;
      left: 0;
    }
    .location-details {
      padding-left: 25px;
    }
  }
}

/*14-10-2022*/

@media only screen and (max-width: 1199px) {
  footer {
    margin-top: 55px;
    .footer-top {
      margin-top: 0;
    }
  }
  .today-free-section {
    .today-free-col {
      padding: 50px;
    }
  }
}
@media only screen and (max-width: 800px) {
  footer {
    margin-top: 40px;
    .footer-top {
      margin-top: 0;
    }
  }
}
@media only screen and (max-width: 991px) {
  .subscription {
    .footer-top {
      padding-top: 131px;
    }
  }
  .footer-newsletter {
    .newsletter {
      .email {
        max-width: 100%;
      }
    }
  }
}
