@media only screen and (max-width: 1399px) {
  .authentication-page {
    .auth-slider {
      .slider-logo {
        img {
          max-width: 272px;
          width: 100%;
        }
        &::after {
          width: 204px;
          height: 60px;
          top: 45px;
          right: -93px;
        }
      }
      .bottom-card-area {
        max-width: 537px;
        bottom: 60px;

        .discription-area {
          font-size: 20px;
          line-height: 1.3;
          margin-bottom: 1.9rem;
        }
        .artist-name {
          font-size: 30px;
        }
        .details-area {
          .artist-type {
            font-size: 16px;
            line-height: 26px;
          }
        }
      }
      button.slick-arrow.slick-next {
        right: 150px;
        bottom: 130px;
        &::before {
          height: 40px;
          width: 40px;
        }
      }
      button.slick-arrow.slick-prev {
        bottom: 130px;
        right: 210px;
        &::before {
          height: 40px;
          width: 40px;
        }
      }
    }
  }
  .artist-profile {
    .tracker {
      span {
        font-size: 13px;
        line-height: 16px;
      }
    }
    &.new-class {
      .artist-name {
        font-weight: 600;
        font-size: 28px;
      }
    }
  }

  .authentication-page {
    .signup-box {
      max-width: 348px;
      width: 100%;
      height: 100%;
    }
  }
  .artist-profile {
    .artist-left-discription {
      font-size: 16px;
      line-height: 24px;
      max-height: 280px;
    }
    .artist-left-title {
      font-size: 28px;
      line-height: 31px;
      margin-bottom: 14px;
    }
    .artist-job-col .job-bottom-title {
      font-size: 28px;
      line-height: 34px;
      margin-top: 12px;
    }
    .job-list-left-col {
      font-size: 16px;
      line-height: 30px;
    }
    .video-title {
      font-size: 16px;
      line-height: 18px;
    }
  }
  .awards-section {
    .card-title {
      font-size: 28px;
      line-height: 30px;
      margin-bottom: 14px;
    }
    .award-icon {
      max-width: 44px;
      min-height: 44px;
    }
  }
  .artist-profile .artist-social-media {
    padding: 20px 20px 20px 20px;
  }
  .artist-profile .artist-social-media a {
    margin-right: 15px;
  }

  img.auth-logo {
    left: 35px;
    position: absolute;
    top: 35px;
  }
}
@media only screen and (max-width: 1299px) {
  .artist-award-container {
    .artist-award-image {
      .icons {
        right: 40px;
      }
    }
    .icons-right {
      right: 55px;
    }
  }
  .authentication-page {
    .auth-slider {
      button.slick-arrow.slick-prev {
        right: 190px;
      }
      button.slick-arrow.slick-next {
        right: 130px;
      }
    }
  }
  .artist-profile {
    .artist-social-media a {
      margin-right: 20px;
    }
  }
}
@media only screen and (max-width: 1199px) {
  .artist-profile {
    &.video-profile {
      .video-artist-bottom-img {
        .artist-img {
          height: 216px !important;
        }
      }
    }
  }
  .authentication-page {
    img.auth-logo {
      top: 20px;
      left: 12px;
    }
    .form-control {
      font-size: 12px;
      line-height: 1.2;
    }
  }
  .artist-community {
    button:not(.transparent-btn) {
      font-size: 14px;
      line-height: 1;
    }
  }
  .authentication-page {
    .signup-box {
      h1 {
        font-size: 22px;
        line-height: 1.4;
      }
    }
    .auth-slider {
      .bottom-card-area {
        max-width: 422px;
        bottom: 24px;

        .discription-area {
          font-size: 16px;
          line-height: 1.3;
          margin-bottom: 1.6rem;
        }
        .artist-name {
          font-size: 26px;
        }
      }
      button.slick-arrow.slick-prev {
        right: 185px;
        bottom: 80px;
        &::before {
          height: 35px;
          width: 35px;
        }
      }
      button.slick-arrow.slick-next {
        right: 130px;
        bottom: 80px;
        &::before {
          height: 35px;
          width: 35px;
        }
      }
    }
  }
  /*home page*/

  header {
    .navbar {
      .navbar-nav .nav-link {
        font-size: 14px;
        margin-right: 4%;
      }
      .offcanvas-body .btn-primary {
        padding: 10px 15px;
        font-size: 14px;
        // line-height: 70%;
      }
    }
  }
  .top-banner {
    .banner-title {
      font-size: 40px;
      line-height: 107%;
    }
    .banner-discription-area {
      font-size: 18px;
      line-height: 110%;
      margin-bottom: 1.5%;
    }
  }
  .artist-community {
    .artist-community-banner {
      min-height: 100%;

      .title-artist-community {
        font-size: 38px;
        line-height: 120%;
      }
      .discription-area {
        font-size: 16px;
        line-height: 130%;
      }
      .artist-name {
        font-size: 28px;
        line-height: 100%;
      }
    }
    .title-artist-community {
      font-size: 35px;
      line-height: 40px;
      margin-bottom: 4%;
    }
    .artist-discription-area {
      font-size: 18px;
      line-height: 120%;
      margin-bottom: 5%;
    }
    button.slick-arrow.slick-prev {
      bottom: 65px;
      right: 127px;
      &::before {
        height: 35px;
        width: 35px;
      }
    }
    button.slick-arrow.slick-next {
      right: 65px;
      z-index: 1;
      bottom: 65px;
      &::before {
        height: 35px;
        width: 35px;
      }
    }
  }

  /*home page end*/
  button.transparent-btn.btn.btn-primary {
    font-size: 18px;
    line-height: 1;
  }

  .artist-profile button {
    width: 120px;
    font-size: 16px;
    line-height: 1.1;
  }
  .artist-about-button {
    display: flex;
    flex-flow: row wrap;
    // justify-content: space-between;
    align-content: center;
  }
}
@media only screen and (max-width: 1023px) {
  .artist-profile {
    &.video-profile {
      .video-artist-bottom-img {
        .artist-img {
          height: 261px;
        }
      }
    }
  }
  .artist-profile .artist-social-media {
    padding: 10px 0 10px 30px;
  }
  .artist-profile .artist-social-media a {
    margin-right: 15px;
  }
  // .music-tab-item {
  //   .list-group-horizontal {
  //     width: 1024px;
  //   }
  // }
  button.navbar-toggler.collapsed,
  button.navbar-toggler:focus,
  button.navbar-toggler:focus-visible {
    border: none;
    box-shadow: none;
  }
  footer {
    .footer-bottom {
      padding-top: 20px;
      padding-bottom: 20px;
    }
    .footer-icons {
      display: flex;
      justify-content: center;
      margin-top: 20px;
    }
    .col-lg-6 {
      padding-bottom: 10px;
      text-align: center;
    }
  }
  button.btn.btn-base-button {
    box-shadow: none;
    font-weight: 700;
    .plus-icon {
      color: #b42318;
    }
  }
  button.btn.btn-login-button {
    box-shadow: none;
    border: none;
  }
  button.btn-close {
    border: none;
  }
  .navbar-toggler {
    border: none;
  }
  .artist-profile .artist-social-media {
    padding: 10px 0 10px 25px;
  }
}
@media only screen and (max-width: 990px) {
  .artist-profile {
    &.video-profile {
      .video-artist-bottom-img {
        .artist-img {
          height: 261px;
        }
      }
    }
    &.new-class {
      .artist-top-col {
        .artist-verifie-tick {
          bottom: -15px;
          right: 0;
          left: 0;
        }
      }
    }
  }
  .artist-profile button {
    width: 113px;
    font-size: 14px;
    line-height: 1.3;
  }
  .authentication-page .auth-slider {
    display: none;
  }
  button.transparent-btn.btn.btn-primary {
    font-size: 14px;
    line-height: 1.3;
  }
  .videos {
    .music-tabs a {
      font-size: 14px;
    }
    .music-video-title {
      font-size: 20px;
      line-height: 110%;
      margin-bottom: 3%;
    }
  }
  .class-add-tab-in-for-awards {
    .nav-tabs .nav-link {
      margin-right: 6px;
    }
  }
}
@media only screen and (max-width: 800px) {
  .top-banner .banner-title {
    font-size: 30px;
    line-height: 90%;
  }
  .top-banner .banner-discription-area {
    font-size: 14px;
    line-height: 24px;
  }
  .top-banner .slick-prev,
  .top-banner .slick-next {
    width: 28px;
    height: 28px;
  }
  .videos .title {
    font-size: 28px;
    line-height: 32px;
    margin-bottom: 18px;
    margin-top: 26px;
  }
  footer .footer-col {
    width: 33.33%;
    margin-bottom: 15px;
    .footer-title {
      font-size: 14px;
      line-height: 20px;
    }
    footer .footer-col label {
      position: absolute;
    }
    .footer-title > ul > li {
      position: relative;
    }
  }
  .artist-profile button {
    width: 114px;
  }
  .artist-profile .artist-job-col .job-bottom-title {
    font-size: 28px;
    line-height: 30px;
    margin-top: 10px;
  }
  .awards-section {
    .card-text {
      font-size: 16px;
      line-height: 22px;
    }
    .award-discription-area {
      font-size: 16px;
      line-height: 19px;
    }
  }
}
@media only screen and (max-width: 575px) {
  .artist-award-container {
    .artist-award-image {
      .icons {
        right: 22% !important;
      }
    }
    .icons-right {
      right: 22% !important;
    }
  }
  .artist-profile button {
    width: 106px;
  }
  .offcanvas.offcanvas-end {
    max-width: 320px;
  }
  .job-description .col-7 {
    width: 134.333333%;
  }

  img.auth-logo {
    position: unset;
    margin: 0px 0 20px 0;
  }
  .authentication-page > .m-0 > .p-0 > .d-flex {
    flex-direction: column;
    max-width: 90%;
    margin: 0 auto;
    align-items: flex-start !important;
    padding-top: 20px;
  }
  .authentication-page .signup-box {
    max-width: 100%;
    width: 100%;
  }
  .artist-community .title-artist-community {
    font-size: 24px;
    line-height: 32px;
  }
  .offcanvas-body button.btn.btn-login-button {
    margin: 0px 30px 0 30px;
  }

  element.style {
    display: block;
  }
  footer .footer-col {
    width: 33%;
    margin-bottom: 15px;
  }
  .artist-about-button.mt-4.d-flex.align-items-center.justify-content-between {
    flex-direction: column;
    float: left;
  }
  .about-button {
    margin-bottom: 10px;
  }
  .artist-profile {
    .artist-social-media {
      padding: 10px 0 10px 30px;
    }
    a {
      margin-right: 0px;
    }
  }

  .artist-profile .artist-social-media {
    padding: 10px 0 10px 13px;
  }
  .artist-profile .artist-social-media a {
    margin-right: 12px;
  }
}
@media only screen and (max-width: 480px) {
  .artist-award-container {
    .artist-award-image {
      text-align: center;
      .icons {
        right: 18% !important;
      }
      .card-img-top{
        width: 235px;
        height: 235px;
      }
    }
    .icons-right {
      right: 20% !important;
    }
  }
  .artist-profile .artist-img.artist-bottom-section {
    height: 268px;
  }
  .artist-profile .artist-img.artist-bottom-section img {
    height: 100%;
  }
  footer {
    .col-lg-6 {
      padding-bottom: 0;
    }
    .copyright {
      font-size: 13px;
      line-height: 20px;
    }
  }
  .top-banner {
    .banner-title {
      font-size: 24px;
      line-height: 90%;
    }
    .slick-next {
      width: 24px;
      height: 24px;
    }
    .slick-prev {
      width: 24px;
      height: 24px;
    }
  }
  .artist-profile .job-list-left-col {
    font-size: 16px;
    line-height: 24px;
  }
  .artist-profile .video-title {
    font-size: 16px;
    line-height: 28px;
  }
  .artist-profile .artist-social-media {
    padding: 10px 0 10px 10px;
  }
  .artist-profile .artist-social-media a {
    margin-right: 10px;
  }
  // .artist-social-media img {
  //   height: 20px;
  // }

  footer .footer-col {
    width: 50%;
    margin-bottom: 15px;
  }
  .artist-profile .job-list-left-col:before {
    bottom: 7px;
  }

}

/*Home page top slider media query*/
@media only screen and (max-width: 992px) {
  .artist-video-slider {
    .banner-item {
      iframe {
        height: 390px;
      }
    }
  }
  .artist-award-container {
    .artist-award-image {
      .icons {
        right: 20px;
      }
    }
    .icons-right {
      right: 25px;
    }
  }
  .artist-profile .artist-img.artist-bottom-section {
    height: 388px;
  }
  .artist-profile .artist-img.artist-bottom-section img {
    height: 100%;
  }
  .today-free-section {
    .today-free-col {
      .free-trial-title {
        font-size: 22px;
        line-height: 33px;
      }
    }
  }
  .subscription-custom-modal {
    max-width: 95% !important;
  }
  .top-banner {
    .banner-discription-area {
      width: 100%;
    }
  }
  .offcanvas-end {
    .dropdown {
      display: inline-block;
      .btn-profile.profile-section {
        border: none;
        box-shadow: none;
        padding: 0;
      }
      .dropdown-menu.show {
        left: -10px;
      }
    }
  }
  .mobile-search {
    position: relative;
    input {
      border: 1px solid #fff;
      color: #667085;
      padding-left: 35px;
      width: 100%;
      font-weight: 400;
      font-size: 15px;
      line-height: 18px;
      border-radius: 8px;
    }
    .search-icon {
      position: absolute;
      left: 22px;
      top: 50%;
      transform: translateY(-50%);
      cursor: pointer;
      color: #667085;
    }
  }
}
@media only screen and (min-width: 576px) {
  .top-banner {
    .slick-slider {
      max-width: 540px;
    }
  }
}
@media only screen and (min-width: 768px) {
  .top-banner {
    .slick-slider {
      max-width: 720px;
    }
  }
}
@media only screen and (min-width: 992px) {
  .subscription-custom-modal {
    max-width: 80% !important;
  }
  .top-banner {
    .slick-slider {
      max-width: 960px;
    }
  }
}
@media only screen and (min-width: 1200px) {
  .top-banner {
    .slick-slider {
      max-width: 1140px;
    }
  }
  .artist-award-container {
    .artist-award-image {
      .icons {
        right: 60px;
      }
    }
    .icons-right {
      right: 75px;
    }
  }
}
@media only screen and (min-width: 1400px) {
  .top-banner {
    .slick-slider {
      max-width: 1320px;
    }
  }
}
/*Home page top slider media query*/
@media only screen and (max-width: 1650px) {
  .artist-profile {
    &.new-class {
      .artist-top-col {
        .artist-verifie-tick {
          bottom: -8px;
        }
      }
    }
    &.video-profile {
      .video-artist-bottom-img {
        .artist-img {
          height: 261px;
        }
      }
    }
  }
  .plan-price {
    .plan-card {
      padding: 22px;
    }
    .monthly-price {
      font-size: 37px;
      line-height: 40px;
    }
  }
  .artist-profile .artist-img.artist-bottom-section {
    height: 320px;
  }
  .artist-profile .artist-img.artist-bottom-section img {
    height: 100%;
  }
  // .artist-profile{
  //   &.video-profile{
  //     .video-artist-bottom-img{
  //       .artist-img{
  //         height: unset;
  //       }
  //     }
  //   }
  // }
}
@media only screen and (max-width: 1300px) {
  .plan-price {
    .monthly-price {
      font-size: 34px;
    }
  }
}
@media only screen and (max-width: 1200px) {
  .header-search.top-banner {
    .banner-search input {
      max-width: 100%;
    }
    .suggestions,
    .no-suggestions {
      max-width: 100%;
    }
  }
  header:before {
    content: "";
    border-bottom: 1px solid #f2f4f7;
    position: absolute;
    width: 100%;
    top: 50%;
    transform: translateY(2px);
  }
  .banner-search-col {
    bottom: 160px;
  }
  .plan-price {
    .monthly-price {
      font-size: 29px;
      line-height: 34px;
    }
  }
  .account-profile-menu-top {
    .banner-search {
      max-width: 410px;
    }
  }
}

@media only screen and (min-width: 1400px) {
  .artist-profile .artist-img.artist-bottom-section img {
    height: 100%;
  }
  .artist-profile {
    .artist-img.artist-bottom-section {
      height: 355px;
    }
    &.video-profile {
      .video-artist-bottom-img {
        .artist-img {
          height: 288px;
        }
      }
    }
  }
  .container,
  .top-banner .slick-slider {
    max-width: 1250px;
  }
  .video-item-col {
    iframe {
      height: 221px;
    }
  }
  // .top-banner {
  //   .no-suggestions,
  //   .suggestions {
  //     max-height: 120px;
  //   }
  // }
  .artist-award-container {
    .artist-award-image {
      .icons {
        right: 40px;
      }
    }
    .icons-right {
      right: 55px;
    }
  }
}

@media only screen and (min-width: 1600px) {
  .artist-profile .artist-img.artist-bottom-section {
    height: 355px;
  }
  .artist-profile .artist-img.artist-bottom-section img {
    height: 100%;
  }
  .container,
  .top-banner .slick-slider {
    max-width: 1450px;
  }
  .video-item-col {
    iframe {
      height: 285px;
    }
  }
  .artist-profile {
    &.video-profile {
      .video-artist-bottom-img {
        .artist-img {
          height: 261px;
        }
      }
    }
  }
  .artist-award-container {
    .artist-award-image {
      .icons {
        right: 55px;
      }
    }
    .icons-right {
      right: 75px;
    }
  }
}

@media only screen and (min-width: 1800px) {
  .container,
  .top-banner .slick-slider {
    max-width: 1750px;
  }
  .video-item-col {
    iframe {
      height: 315px;
    }
  }
  .artist-profile {
    .artist-bottom-col {
      .artist-img {
        height: 470px;
        iframe {
          height: 100%;
        }
      }
    }
    &.video-profile {
      .video-artist-bottom-img {
        .artist-img {
          height: 413px;
        }
      }
    }
  }
  .artist-award-container {
    .artist-award-image {
      .icons {
        right: 80px;
      }
    }
    .icons-right {
      right: 100px;
    }
  }
}
.btn-profile {
  .profile-section {
    box-shadow: none;
  }
}

@media only screen and (min-width: 481px) and (max-width: 768px) {
  .artist-community button.slick-arrow.slick-prev {
    right: 100px;
  }
  .artist-community button.slick-arrow.slick-next {
    right: 50px;
  }
  .videos .title {
    font-size: 26px;
    line-height: 30px;
  }
}

@media only screen and (max-width: 480px) {
  .artist-community {
    button {
      &.slick-arrow{
        &.slick-next {
            right: 5%;
            height: 25px !important;
            width: 25px !important;
          &::before{
            height: 25px;
            width: 25px;
            background-size: 10px;
          }
        }
        &.slick-prev {
            right: 15%;
            height: 25px !important;
            width: 25px !important;
          &::before{
            height: 25px;
            width: 25px;
            background-size: 10px;
          }
        }
      }
    }
  }
  .artist-video-slider {
    .banner-item {
      iframe {
        height: 233px;
      }
    }
  }
  .artist-top-col {
    .artist-img {
      height: 330px;
      img {
        height: 330px;
      }
    }
  }
  .artist-profile {
    &.new-class {
      .artist-top-col {
        .artist-img {
          height: unset;
          img {
            height: unset;
          }
        }
      }
    }
  }
}
/*13*-10-2022*/
@media only screen and (max-width: 767px) {
  .red-rider {
    .edit-icon {
      position: unset;
    }
  }
  footer {
    .footer-col {
      &.contact-form-col {
        width: 100%;
      }
    }
    .footer-discription-area {
      margin-bottom: 0;
    }
  }
  .chat-section {
    .chats {
      height: calc(100vh - 193px);
    }
  }
  .awards-section {
    .award-items {
      grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
    }
  }
}
@media only screen and (max-width: 575px) {
  footer {
    .footer-col {
      width: 100%;
    }
  }
  /*27/01/2023*/
  .mobile-left-col {
    max-width: 35%;
  }
  .mobile-right-col {
    max-width: 65%;
  }

  .artist-profile.new-class {
    .artist-name {
      font-size: 15px;
      line-height: 1.5;
    }
    .job-title {
      font-size: 15px;
      line-height: 17px;
      margin-bottom: 5px;
    }
    .artist-social-media {
      margin-top: 10px;
    }
    .artist-top-col {
      .artist-verifie-tick{
        img{
          width: 20px;
        }
      }
    }
    .claim-button button {
      font-size: 11px;
      padding: 5px 5px;
      height: 30px;
    }
  }
  .artist-profile {
    .artist-social-media a {
      width: 28px;
      img{
        width: 25px;
        height: 25px;
      }
    }
  }
  /*27/01/2023*/
}
